import React, { useEffect } from 'react'
import NavBar from '../../Components/NavBar';
import "./Legal.css";

export default function TermsAndConditions() {
    useEffect(() => {
        makeItWork()
    }, [])
    
    const makeItWork = () =>{
        var tab_lists = document.querySelectorAll(".tabs_list ul li");
        var tab_items = document.querySelectorAll(".tab_item"); 

        tab_lists.forEach(function(list){
        list.addEventListener("click", function(){
            var tab_data = list.getAttribute("data-tc");
            
            tab_lists.forEach(function(list){
            list.classList.remove("active");
            });
            list.classList.add("active");
            
            tab_items.forEach(function(item){
            var tab_class = item.getAttribute("class").split(" ");
            if(tab_class.includes(tab_data)){
                item.style.display = "block";
            }
            else{
                item.style.display = "none";
            }
            
            })
            
        })
        })
    }
  return (
    <div className="wrapper flex_align_justify">
              <NavBar/>

   <div className="tc_wrap">
       <div className="tabs_list">
         <ul>
           <li data-tc="tab_item_1" className="active">Terms of use</li>
           <li data-tc="tab_item_2">Privacy Policy</li>
         </ul>
       </div>
       <div className="tabs_content">
          <div className="tab_head">
            <h2>Terms & Conditions</h2>
          </div>
          <div className="tab_body">
            <div className="tab_item tab_item_1">
                <h3>Terms of use</h3>
                <p>Welcome to Swiff, operated by Expert Alliance Ltd. These Terms and Conditions ("Terms") govern your use of the Swiff website (the "Website"), <br/> including any services provided through the Website. By accessing or using the Website, <br/>you agree to be bound by these Terms. Please read them carefully.</p>
                <div class="list-wrap">

                    <div class="list">
                        <div>
                            <b>Acceptance of Terms </b>
                            <p className='para'>By accessing or using the Website, you represent and warrant that you have read, understood, and agree to be bound by these Terms. If you do not agree with these Terms, you may not access or use the Website.</p>

                        </div>
                        <div>
                            <b>Description of Service</b>
                            <p className='para'>Swiff is an online platform that allows users to purchase airtime and data for their loved ones abroad. Additionally, users can send virtual gifts to their loved ones through the Website. The service provided by Swiff is subject to these Terms.</p>

                        </div>
                        <div>
                            <b>Account Registration and Security</b>
                            <p className='para'>To use certain features of the Website, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. You must notify us immediately of any unauthorized use or breach of security. We reserve the right to refuse service, terminate accounts, or remove or edit content in our sole discretion.</p>

                        </div>
                        <div>
                            <b>User Conduct</b>
                            <p className='para'>You agree to use the Website in compliance with applicable laws and these Terms. When using the Website, you agree not to:

                                    <p>a. Violate any applicable laws or regulations;</p>
                                    <p>b. Infringe the intellectual property rights or other rights of any third party;</p>
                                    <p>c. Use the Website for any illegal or unauthorized purpose;</p>
                                    <p>d. Interfere with or disrupt the operation of the Website or the servers or networks used to make the Website available;</p>
                                    <p>e. Engage in any activity that could harm or damage the reputation, goodwill, or interests of Swiff or Expert Alliance;</p>
                                    <p>f. Use any automated systems, such as bots or scrapers, to access or collect data from the Website without our prior written consent;</p>
                                    <p>g. Attempt to gain unauthorized access to any part of the Website or any other systems or networks connected to the Website.</p>
                            </p>

                        </div>
                        <div>
                            <b>Payments and Refunds</b>
                            <p className='para'>
                                    <p>a.Payment for airtime, data, and virtual gifts must be made through the payment methods available on the Website. You agree to provide accurate and valid payment information.</p>
                                    <p>b. All payments are non-refundable unless otherwise specified or required by applicable law. We reserve the right to refuse or cancel any order at any time for any reason.</p>
                                    <p>c. In the event of a refund, the refund amount will be determined at our sole discretion.</p>
                            </p>
                        </div>

                        <div>
                            <b>Intellectual Property</b>
                            <p className='para'>All content, trademarks, logos, and other intellectual property displayed on the Website are the property of Swiff or its licensors. You may not use, reproduce, modify, or distribute any content from the Website without our prior written permission.</p>

                        </div>

                        <div>
                            <b>Limitation of Liability</b>
                            <p className='para'>To the extent permitted by applicable law, Expert Alliance shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the Website or these Terms.</p>

                        </div>

                        <div>
                            <b>Termination</b>
                            <p className='para'>We may terminate or suspend your access to the Website at any time and without prior notice if we believe you have violated these Terms or for any other reason at our sole discretion.</p>

                        </div>

                        <div>
                            <b>Modifications to the Terms</b>
                            <p className='para'>We reserve the right to modify these Terms at any time without prior notice. By continuing to access or use the Website after the revised Terms have been posted, you agree to be bound by the modified Terms.</p>

                        </div>

                        <div>
                            <b>Governing Law and Jurisdiction</b>
                            <p className='para'>These Terms shall be governed by and construed in accordance with the laws of England. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of England.</p>

                        </div>

                        <div>
                            <b>Severability</b>
                            <p className='para'>If any provision of these Terms is found to be unenforceable or invalid, the remaining provisions shall be unaffected and remain in full force and effect.</p>

                        </div>
                        <div>
                            <b>Entire Agreement</b>
                            <p className='para'>These Terms constitute the entire agreement between you and Swiff concerning the use of the Website, superseding any prior agreements or understandings, whether written or oral. 
                            If you have any questions or concerns regarding these Terms, please contact us at: support@swiff.com </p>

                        </div>

                    </div>

                </div>
            </div>
            <div className="tab_item tab_item_2" style={{display: "none"}}>
                <h3>Privacy Policy</h3>
                <p>This Privacy Policy (&quot;Policy&quot;) explains how Expert Alliance, operating Swiff (&quot;we&quot; or &quot;us&quot;),
                    collects, uses, and protects the personal information you provide while accessing or using the
                    Swiff website (the &quot;Website&quot;). We are committed to protecting your privacy and ensuring the
                    security of your personal information. By accessing or using the Website, you agree to the
                    collection, use, and disclosure of your personal information as described in this Policy.</p>
                <div class="list-wrap">

                    <div class="list">
                         <div>
                            <b>Information We Collect</b>
                            <p className='para'>

                                    <p>a. Personal Information: When you register an account on the Website, we may collect personal
information such as your name, email address, and billing information.</p>
                                    <p>b. Transaction Information: When you make a purchase on the Website, we collect transaction
details, including the products or services purchased and payment information.</p>
                                    <p>c. Communication Information: We may collect information from your communications with us,
such as inquiries, feedback, or customer support requests.</p>
                                    <p>d. Usage Information: We automatically collect certain information about your use of the
Website, including your IP address, browser type, device information, and browsing activities.</p>

                            </p>

                        </div>
                        <div>
                            <b>Use of Information</b>
                            <p className='para'>We use the information collected for the following purposes:

                                    <p>a. To provide and improve our services, including processing your transactions, delivering
airtime and data to recipients, and facilitating virtual gift sending.</p>
                                    <p>b. To communicate with you, respond to your inquiries, and provide customer support.</p>
                                    <p>c. To personalize and enhance your experience on the Website.</p>
                                    <p>d. To analyze and understand how users interact with the Website, monitor usage patterns, and
perform statistical analysis to improve our services.</p>
                                    <p>e. To enforce our Terms and Conditions and protect the rights, property, and safety of Swiff,
Expert Alliance, and others.</p>
                                    <p>f. Information Sharing and Disclosure</p>
                            </p>

                        </div>
                        
                        <div>
                            <b>Information Sharing and Disclosure</b>
                            <p className='para'>We may share your personal information in the following circumstances:

                                    <p>a. With service providers, business partners, or third-party vendors who assist us in operating
the Website and providing our services. These third parties are bound by confidentiality
obligations and are prohibited from using your personal information for any other purposes.</p>
                                    <p>b. With your consent or at your direction.</p>
                                    <p>c. To comply with legal obligations, enforce our rights, or protect the rights, property, or safety of
Swiff, Expert Alliance, our users, or others.</p>
                                    <p>d. In connection with a corporate transaction, such as a merger, acquisition, or sale of assets,
where your personal information may be transferred as part of the transaction.</p>
                            </p>

                        </div>

                        <div>
                            <b>Data Security</b>
                            <p className='para'>We implement appropriate technical and organizational measures to protect your personal<br/>
                                information against unauthorized access, alteration, disclosure, or destruction. However, please<br/>
                                note that no method of transmission over the Internet or electronic storage is 100% secure, and<br/>
                                we cannot guarantee absolute security.</p>

                        </div>

                        <div>
                            <b>Data Retention</b>
                            <p className='para'>
                                We retain your personal information for as long as necessary to fulfill the purposes outlined in<br/>
                                this Policy, unless a longer retention period is required or permitted by law.
                            </p>
                        </div>

                        

                        <div>
                            <b>Children&#39;s Privacy</b>
                            <p className='para'>The Website is not intended for individuals under the age of 18. We do not knowingly collect
                                personal information from children. If we become aware that we have collected personal
                                information from a child without appropriate consent, we will take steps to delete that
                                information.</p>

                        </div>

                        <div>
                            <b>Third-Party Links</b>
                            <p className='para'>
                            The Website may contain links to third-party websites or services. We are not responsible for
                            the privacy practices or content of those third parties. We encourage you to review the privacy
                            policies of any third-party websites or services you visit.
                            </p>

                        </div>

                        <div>
                            <b>Changes to the Privacy Policy</b>
                            <p className='para'>
                            We reserve the right to update or modify this Policy at any time. We will notify you of any
                            material changes by posting the updated Policy on the Website or by other means. Your
                            continued use of the Website after the changes are implemented constitutes your acceptance of
                            the revised Policy.
                            </p>

                        </div>

                        <div>
                            <b>Contact Us</b>
                            <p className='para'>If you have any questions, concerns, or requests regarding this Policy or the
privacy of your personal information, please contact us at support@swiff.com</p>

                        </div>
                    </div>

                </div>
            </div>
           
            </div>
          <div className="tab_foot flex_align_justify" style={{display:"none"}}>
            <button className="decline">
              Decline
            </button>
            <button className="agree">
              Agree
            </button>
          </div>
       </div>
   </div>
</div>
  )
}
