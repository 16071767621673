import React from 'react'

function CountryDropdown({...props}) {

   
  return (
        <div className='country-dropdown'>
            <div className='country-dropdown-wrapper'>
                {/*Drop down rows*/}
                {props.countryCallingCodes && props.countryCallingCodes.map((codes) => {
                    return(
                        <div className='country-row' key={codes.id} onClick={() => props.handleCountrySelect(codes.calling_code, codes.flag_url)}>
                        <div className='country'>
                            <img className="country-flag" src={codes.flag_url} alt="Ghana flag"/>
                            <div className="country-name">{codes.name}</div>
                        </div>
                        <div className='flag-code'>+{codes.calling_code}</div>
                    </div>
    
                    )
                                           
                })}
               
            </div>
        </div> 
    )
}

export default CountryDropdown