import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className="main-footer">
      <div className="main-footer-wrapper">
        <ul className="main-footer-details">
          <li>FAQs</li>
          <Link to={'/terms-and-conditions'}><li>Terms & Conditions</li></Link>
         
          <Link to={'https://wa.me/message/QH7T7G72L6YXO1'} ><li>Customer Support</li></Link>
          <li>
            <img className="main-footer-logo" src="../../../favicon/swiff-logo.svg" alt="Swiff"/>
          </li>
        </ul>
        <div className="main-footer-copyright">
          Copyright 2023, All rights reserved
        </div>
      </div>
    </div>
  )
}
