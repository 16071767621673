import React from 'react'

function ProductDropdown({...props}) {
  return (
        <div className='product-dropdown'>
            <div className='product-dropdown-wrapper'>
                {/*Drop down rows*/}
                <div className='product-row'>
                    <div className='product' onClick={() => props.handlePurchaseTypesSelect("Buy Data")}>
                        <img className="product-flag" src="../../favicon/data.svg" alt="Ghana flag"/>
                        <div className="product-name">Buy Data</div>
                    </div>
                </div>
                <div className='product-row'>
                    <div className='product' onClick={() => props.handlePurchaseTypesSelect("Buy Airtime")}>
                        <img className="product-flag" src="../../favicon/airtime.svg" alt="Ghana flag"/>
                        <div className="product-name">Buy Airtime</div>
                    </div>
                </div>
                <div className='product-row'>
                    <div className='product' onClick={() => props.handlePurchaseTypesSelect("Pay Utility Bills")}>
                        <img className="product-flag" src="../../favicon/utility-icon.svg" alt="Ghana flag"/>
                        <div className="product-name">Pay Utility Bills</div>
                    </div>
                </div>
                 
                <div className='product-row'>
                    <div className='product' onClick={() => props.handlePurchaseTypesSelect("Pay TV Bills")}>
                        <img className="product-flag" src="../../favicon/tv-bills.svg" alt="Ghana flag"/>
                        <div className="product-name">Pay TV Bills</div>
                    </div>
                </div>

                {/* <div className='product-row'>
                    <div className='product' onClick={() => props.handlePurchaseTypesSelect("Send Gift Voucher")}>
                        <img className="product-flag" src="../../favicon/voucher.svg" id="filter-white" alt="Ghana flag"/>
                        <div className="product-name">Send Gift Voucher</div>
                    </div>
                </div> */}
            </div>
        </div> 
    )
}

export default ProductDropdown