import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FailedModal from '../../Components/FailedModal';
import LoaderModal from '../../Components/LoaderModal';
import { configs } from '../../Configs/app-configs'
import DynamicSuccessModal from '../../Components/DynamicSuccessModal';


export default function ConfirmEmail() {
    const [failureToggle, setFailureToggle] = useState(false);
    const [loaderToggle, setLoaderToggle] = useState(false);
    const[message, setMessage] = useState("");

    //dynamic success dependencies
    const [dynamicSuccessModalToggle, setDynamicSuccessModalToggle] = useState(false);
    const [dynamicSuccessTitle, setDynamicSuccessTitle] = useState("");
    const [dynamicSuccessMessage, setDynamicSuccessMesssage] = useState("");

    const {code} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(code);

        setTimeout(function(){
            setLoaderToggle(true)
        }, 1000)

        setTimeout(function(){
            console.log(localStorage.getItem('email'))
            axios.post(configs.apiUrl+'/verify/email/confirmation', {
                'email':localStorage.getItem('email'),
                'code': code
            }).then(response => {
                console.log(response.data, response.status, response.data.success );
                if(response.status === 200 && response.data.success){
                     if(response.data.email_confirmed === '0'){
                        setTimeout(function(){
                            runErrorMechanism(response)

                            setTimeout(function(){
                                 navigate("/login");
                            }, 3000)
                        }, 2000)
                     }else{
                         localStorage.setItem('emailConfirmed', response.data.email_confirmed)
                         setTimeout(function(){
                            setTimeout(function(){
                                navigate("/login");
                            }, 7000)
                            showSuccess('Successful!', 'Email Confirmation')
                        }, 1000)
                     }
                }else{
                    setTimeout(function(){
                        runErrorMechanism(response)
                        setTimeout(function(){
                            navigate("/login");
                       }, 3000)
                    }, 2000)
                }
            }); 
        }, 2000)
       
      
    }, [])


    const setFailureMessage = (message) =>{
        setMessage(message)
    }
    
    const runErrorMechanism = (response) =>{
        setFailureMessage(response.data.message ?? "Something went wrong, contact Swiff Support")
        setFailureToggle(true);
    }

    const showSuccess = (title, messageDetails) =>{
        setDynamicSuccessMesssage(title);
        setDynamicSuccessTitle(messageDetails);
        setTimeout(function(){
          setDynamicSuccessModalToggle(true)
        }, 1000);
        setDynamicSuccessModalToggle(false)
        setLoaderToggle(false)    
    
      }
    
  return (
      
    <div>
         {
          loaderToggle && (
            <LoaderModal/>
            )
          }


         {
          failureToggle && (
            <FailedModal message={message} failureToggle={failureToggle} setFailureToggle={setFailureToggle}/>
            )
          }

        {
            dynamicSuccessModalToggle && (
              <DynamicSuccessModal message={dynamicSuccessMessage}  
              title={dynamicSuccessTitle}  
              dynamicSuccessModalToggle={dynamicSuccessModalToggle}
              setDynamicSuccessModalToggle={setDynamicSuccessModalToggle}
              />
            )
          }

    </div>
  )
}
