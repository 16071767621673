import React, { useEffect, useState } from 'react'
import './Index.css';
import { Link, useNavigate } from 'react-router-dom';
import CountryDropdown from '../../Components/CountryDropdown';
import NavBar from '../../Components/NavBar';
import { configs } from "../../Configs/app-configs";
import { colorsCodes, titleColorsCodes } from "../../Configs/background-configs";
import axios from 'axios';
import ReactGA from  'react-ga4';
import PromoModal from '../../Components/PromoModal';
import FailedModal from '../../Components/FailedModal';
import LoaderModal from '../../Components/LoaderModal';
import SendingModal from '../../Components/SendingModal';
import SuccessModal from '../../Components/SuccessModal';


export default function Index() {

  const [value, setValue] = useState(0);
  const [selectCountryName, setSelectCountryName] = useState("");
  const [selectCallingCode, setSelectCallingCode] = useState("");
  const [selectCountryFlag, setSelectCountryFlag] = useState("");
  const [countryCurrency, setCountryCurrency] = useState("");
  const [callingCode, setCallingCode] = useState("");
  const [countryFlag, setCountryFlag] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [toggle, setToggle] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [countryCallingCodes, setCountryCallingCodes] = useState([]);
  const [failedValidation, setFailedValidation] = useState(false);
  const [failedValidationMessage, setFailedValidationMessage] = useState("");
  const [failureToggle, setFailureToggle] = useState(false);
  const[message, setMessage] = useState("");


//promo stuff
const [promoToggle, setPromoToggle] = useState(false);
const [loaderToggle, setLoaderToggle] = useState(false);
const[recipientGets, setRecipientGets] = useState("");
const [sendingToggle, setSendingToggle] = useState(false);
const [successToggle, setSuccessToggle] = useState(false);



  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Index Page" });
    setChangingBackground();
    const getIpAndCountry = async() => {
        const response = await axios.get(configs.ipAddressUrl).then((response) => {
           if (response.status === 200 && response.data) {
               if(response.data.country){
                getIpAndCountryFromLocal(response.data.ip, response.data.country);
                localStorage.setItem('ip', response.data.ip);
             }
               return true;
           } else{
               return false;
           }
         });            
     }

      getIpAndCountry()

      setCallingCode("233")
      setCountryFlag("https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg");
        
    }, []);

  const setChangingBackground = () =>{
    const interval = setInterval(() => {
        setValue((v) => (v === 2 ? 0 : v + 1));
      }, 4000);    
  }
  const getIpAndCountryFromLocal = async(ip,country) => {
      await axios.post(configs.apiUrl+'/country/setup', {
          "ip": ip,
          "country": country
      }).then((response) => {
          
        if (response.status === 200 && response.data) {
            setSelectCallingCode(response.data.data.countryLocated.calling_code) 
            localStorage.setItem('countryCallingCode', response.data.data.countryLocated.calling_code) 

            setSelectCountryName(response.data.data.countryLocated.name)
            setSelectCountryFlag(response.data.data.countryLocated.flag_url)
            setCountryCallingCodes(response.data.data.callingCodesByCountry)
            setCountryCurrency(response.data.data.countryLocated.currency_code);

        } 
      });
  }


  const handleCountrySelect = (code,flag) =>{
    setCallingCode(code);
    setCountryFlag(flag)
    setTimeout(function(){
        setToggle(!toggle);
    }, 500)
  }
  const handleTopUp = async (e) =>{
    e.preventDefault()
    if(accountNumber.length < 5){
        e.preventDefault()
            setFailedValidation(true)
            setFailedValidationMessage("Number Invalid");
        setTimeout(function(){
            setFailedValidation(false)
        }, 2000)
        
    }else{
    //store other details in localstorage
    setLocalStorage()
    const response = await checkIfNumberBlocked();
    console.log("response", response)
    if(!response){
      console.log("passed response");
      //set country phone code and redirect to top up panel page
      navigate("/topup/"+callingCode+"/"+accountNumber);
    }
    
    }
    
}

const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "accountNumber") {
      setAccountNumber(value);
    } 

    if (name === "phoneNumber") {
      setAccountNumber(value);
    } 

}

const setLocalStorage = () =>{
    localStorage.setItem("countryFlag", countryFlag)
    localStorage.setItem("currencyCode", countryCurrency)
    localStorage.setItem('callingCodes', JSON.stringify(countryCallingCodes))
}

const getToken = () =>{
  if(localStorage.getItem('token') === "null" ||  localStorage.getItem('token') === null){
    return false;
  }else{
    return true;
  }
}

const hasPromo = () =>{
  if(localStorage.getItem('hasPromo') && localStorage.getItem('hasPromo') ==='1'){
    return true;
  }else{
    return false;
  }
}

const setFailureMessage = (message) =>{
  setMessage(message)
  }

const runErrorMechanism = (response) =>{
  setFailureMessage(response ?? "Something went wrong, contact Swiff Support")
  setFailureToggle(true);
 
  }

const checkIfNumberBlocked = async() =>{
  const response = axios.get(configs.apiUrl+'/transaction/get/block/number/'+accountNumber,{headers:{
          'ContentType':'application/json'
      }}).then(response => {
    if(response.status === 200 && response.data.blocked){
      runErrorMechanism(response.data.data)
      return true
    }else{
      return false
    }
    }); 

    return response;
}


const closeAndOpenModals = (toggle) => {
  if(toggle){
    setSuccessToggle(false);
  }  
  navigate('/')
 }


  return (
    <>
    {
    failureToggle && (
      <FailedModal message={message} failureToggle={failureToggle} setFailureToggle={setFailureToggle}/>
      )
    }

        {
          loaderToggle && (
            <LoaderModal/>
            )
          }

       



        { 
          promoToggle && (
            <PromoModal
                toggle={toggle}
                promoToggle={promoToggle}
                setToggle={setToggle}
                code = {callingCode}
                handleCountrySelect={handleCountrySelect}
                countryFlag={countryFlag}
                countryCallingCodes={countryCallingCodes}
                phoneNumber={accountNumber}
                accountNumber={accountNumber}
                handleChange={handleChange}
                setPromoToggle={setPromoToggle}
                setLoaderToggle={setLoaderToggle}
                setRecipientGets= {setRecipientGets}
                setSendingToggle={setSendingToggle}
                setSuccessToggle={setSuccessToggle}
                runErrorMechanism={runErrorMechanism}
            />
          )
        }

      {
          sendingToggle && (
            <SendingModal code = {callingCode}  recipientGets={recipientGets} recipientNumber={accountNumber}/>
          )
        }

        {
          successToggle && (
            <SuccessModal
             closeAndOpenModals={closeAndOpenModals} />
            )
          }
    <div className='homepage-container' style={{ backgroundColor: colorsCodes[value] }}>
      <NavBar/>

      <div className='body-content'>
        <div className='body-content-wrappper'>
          {/*Dynamic Header*/}
          <div className='body-content-main-header-flex'>
            <div className='body-content-main-header'>
              {/* Send Data top-up for any network */}
              {
                      value === 0  ? <span style={{ color: titleColorsCodes[value]}} >Buy Airtime for friends and 
                                                family</span> :
                      value === 1  ? <span style={{ color: titleColorsCodes[value]}} >  Send Data  
                                                                                          top-up for any  
                                                network</span>  :
                      value === 2  ? <span style={{ color: titleColorsCodes[value]}} >Pay TV bills, so 
                                              they never miss  
                                                a show</span> : ""
                  }
            </div>
          </div>
          
          <div className='recipient-section-wrap'>
             {
               !getToken() && (
                <Link to={'/profile'}>
                  <div className='promo'>
                    <div className='promo-wrap'>
                      <div className='giftbox-wrap'>
                        <img className="giftbox-gif" src="../../../gifs/giftbox.gif" alt="Hamburger-icon"/>
                        <div className='promo-message'>Claim your $1.00 welcome gift</div>
                      </div>
                      <div className='promo-cta'>Send</div>
                    </div>
                  </div>
                </Link>
               )

             }     
               

               {
               getToken() &&  hasPromo() ?
                <div onClick={() => setPromoToggle(!promoToggle)}>
                  <div className='promo'>
                    <div className='promo-wrap'>
                      <div className='giftbox-wrap'>
                        <img className="giftbox-gif" src="../../../gifs/giftbox.gif" alt="Hamburger-icon"/>
                        <div className='promo-message'>Claim your $1.00 welcome gift</div>
                      </div>
                      <div className='promo-cta'>Redeem</div>
                    </div>
                  </div>
                </div>
               :""

             }     
               
             
            
            <div className='recipient-section'>
              <form onSubmit={handleTopUp} >
                  <div className='recipient-section-header'>Who are you sending <br/> top-up to?</div>
                  {/*Input and Dropdown section*/}
                  <div className='recipient-input' >
                    <div className='recipient-input-wrapper' onClick={() => setToggle(!toggle)}>
                      <img className="country-flag" style={{ borderRadius:"60%", overflow:"hidden", objectFit: "cover"}}  src={countryFlag} alt="Ghana flag"/>
                      <div className='country-code'>+{callingCode}</div>
                      <img className="arrow-down" src="favicon/arrow-down.svg" alt="arrow-down-icon"/>
                    </div>
                    <input 
                    className='recipient-number' 
                    name="accountNumber"             
                    value={accountNumber}
                    onChange={handleChange}
                    type="number"                 
                    placeholder="Enter recipient number"/>
                              
                    {toggle && (
                      <CountryDropdown
                        handleCountrySelect={handleCountrySelect}
                        countryCallingCodes={countryCallingCodes}
                      />
                    )}
                  </div>
                      {
                          failedValidation && (
                              <span style={{
                                  fontSize:"12px", 
                                  float:"right",
                                  color:"red",
                                  paddingBottom:"10px",
                                  paddingRight:"12px",
                              }}>{failedValidationMessage}</span>
                          )
                      }
                  

                  {/*Submit Button*/}
                  <input className='recipient-cta' type="submit" value="Buy top-up Gift"/>
                </form> 
              </div>
          </div>
      

          {/*Sender Location*/}
          <div className='sender-location'>
            <div className='sender-location-wrapper'>
              <img className="sender-location-flag" src={selectCountryFlag} alt="Ghana flag"/>
              <div className="sender-location-text">
                You are currently <br/>in 
                <span className="sender-country">
                  {selectCountryName}
                </span>
              </div>
            </div>
          </div>

         

              {/*homepage terms and conditions */}
              <div className='homepage-footer-support'>
                  <div className='homepage-footer-support-wrapper'>
                  <li><Link to={'/support'} >Customer Support</Link></li>                         
                </div>
              </div>
          {/*homepage terms and conditions */}
          <div className='homepage-footer-terms'>
            <div className='homepage-footer-terms-wrapper'>
              <li> <Link to={'/terms-and-conditions'}>Terms & Conditions/Privacy Policy</Link></li>              
            </div>
          </div>

           {/*homepage footer*/}
           <div className='homepage-footer'>
            <div className='homepage-footer-wrapper'>
              <div className='homepage-footer-text'>Expert Alliance Group, All rights reserved </div>  
            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

