import './App.css';

import { BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";



import Purchase from './Pages/Purchase/Purchase';
import Checkout from './Pages/Checkout/Checkout';
import Index from './Pages/Index/Index';
import Signup from './Pages/Signup/Signup';
import Profile from './Pages/Profile/Profile';
import Login from './Pages/Login/Login';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import TagManager from 'react-gtm-module/dist/TagManager';
import TermsAndConditions from './Pages/Legal/TermsAndConditions';
import ConfirmEmail from './Pages/Profile/ConfirmEmail';
import Support from "./Pages/Support/Support";

ReactGA.initialize('G-1F8CKH8YQP', []);

function App() {
  useEffect(() => {
    ReactGA.initialize('G-1F8CKH8YQP', []);
    TagManager.initialize({ gtmId: 'GTM-NXXRDL2' });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "" });

  }, [])

 
  return (
    <div className="App">
     
     <Router>
           <Routes>
              {/* Swiff Routes */}
              <Route  path="/"  element={<Index/>} />
              <Route  path="/login"  element={<Login/>} />
              <Route  path="/signup"  element={<Signup/>} />
              <Route  path="/profile"  element={<Profile/>} />
              <Route path="/topup/:code/:accountNumber" element={<Purchase/>} />
              <Route path="/comfirm/email/:code" element={<ConfirmEmail/>} />
              <Route path="/checkout/:code/:accountNumber/:purchaseTypeId" element={<Checkout/>} />

              {/* Legal Routes */}
              <Route  path="/terms-and-conditions"  element={<TermsAndConditions/>} />
              <Route  path="/support"  element={<Support/>} />
              {/* Legal Routes End */}

              

              {/* Swiff Routes End */}
            </Routes>       
       
    </Router>
    
    
    </div>
  );
}

export default App;
