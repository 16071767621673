import React from 'react'
import PrimaryButton from './PrimaryButton'


function FailedModal({...props}){
    
  return (
    <div className="success-modal">
        <div className="success-modal-wrapper">
            <div className="success-gif-container">
                <img className="success-gif" src="../../../gifs/error.gif" alt="Hamburger-icon"/>
            </div>
            <div className="success-modal-title">Something Went Wrong</div>
            <div className="success-modal-dynamic-text">{props.message}</div>
            <div> 
            <button onClick={() => props.setFailureToggle(!props.failureToggle)} className='main-primary-button'>
                {props.name ?? 'Ok'}
            </button>   
        </div>
        </div>  
    </div>
    )
}

export default FailedModal