export const getInitails = () => {
    if(localStorage.getItem('fullname') == ''){
        // let name = localStorage.getItem('fullname');
        // let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    
        // let initials = [...name.matchAll(rgx)] || [];
    
        // initials = (
        // (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        // ).toUpperCase();
    
        // return initials;
    
    }else{

        try {
            let alias;
            if(localStorage.getItem('fullname').split(' ')){
                alias = localStorage.getItem('fullname').split(' ');
            }else{
                alias = localStorage.getItem('email').split(' ')
            }
            var names = alias,
            initials = names[0].substring(0, 1).toUpperCase();
        
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        } catch (error) {
            console.log(error)
        }
       
    }
   
}