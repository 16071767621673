import React, { useEffect, useState } from 'react'
import './Profile.css';
import NavBar from '../../Components/NavBar';
import { Link, useNavigate } from 'react-router-dom';
import { configs } from '../../Configs/app-configs';
import axios from 'axios';
import LoaderModal from '../../Components/LoaderModal';
import FailedModal from '../../Components/FailedModal';
import DynamicSuccessModal from '../../Components/DynamicSuccessModal';
import ReactGA from  'react-ga4';
import PromoModal from '../../Components/PromoModal';
import SendingModal from '../../Components/SendingModal';
import SuccessModal from '../../Components/SuccessModal';
import PhoneNumber from '../../Components/PhoneNumber';


export default function Profile() {
  const [token, setToken] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [ssoId, setssoId] = useState("");
  const [hasPromo, setHasPromo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [toggle, setToggle] = useState(false);


  const [loaderToggle, setLoaderToggle] = useState(false);
  const [dynamicSuccessModalToggle, setDynamicSuccessModalToggle] = useState(false);
  const [dynamicSuccessTitle, setDynamicSuccessTitle] = useState("");
  const [dynamicSuccessMessage, setDynamicSuccessMesssage] = useState("");

  const [failureToggle, setFailureToggle] = useState(false);
  const[message, setMessage] = useState("");
  const[password, setPassword] = useState("");

  const[currentPassword, setCurrentPassword] = useState("");

  const[recipientGets, setRecipientGets] = useState("");


  const[newPassword, setNewPassword] = useState("");

  const[repeatPassword, setRepeatPassword] = useState("");

  const [promoToggle, setPromoToggle] = useState(false);

  const [countryFlag, setCountryFlag] = useState("https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg");

  const [callingCode, setCallingCode] = useState("233");

  const [accountNo, setAccountNo] = useState("");

  const [countryCallingCodes, setCountryCallingCodes] = useState([]);

  const [successToggle, setSuccessToggle] = useState(false);


  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()
  const [profilePic, setProfilePic] = useState()
  const [transactionLoader, setTransactionLoader] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [passwordType, setPasswordType] = useState("password");
  const [failedValidation, setFailedValidation] = useState(false);
  const [failedValidationMessage, setFailedValidationMessage] = useState("");
  const [sendingToggle, setSendingToggle] = useState(false);

//phone number 
const [phoneToggle, setPhoneToggle] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    // if(!localStorage.getItem('token') || localStorage.getItem('emailConfirmed') === '0' ){
    //   navigate('/login')
    // }
    if(!localStorage.getItem('token')){
      navigate('/login')
    }
    if(!localStorage.getItem('phoneNumber')){
      setPhoneToggle(true)
    }
    getAuthDetails()
    getTransactions()
    getLocalStorageItems()
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Profile Page" });
    
  }, [token, profilePic])
  
  
  const getTransactions = async() => {
    setTransactionLoader(true);
    try {
      await axios.get(configs.apiUrl+"/transaction/swiff/data", {headers:{
        'ContentType':'application/json',
        'Authorization':'Bearer '+localStorage.getItem('token')}})
             .then((response) => {
               console.log(response.data);
               if(response.status == 200 && response.data.status != 403){
                  setTransactionLoader(false);
                  setTransactions(response.data)
               }else{
                navigate('/login')
               }
          }).catch(error => {
              navigate('/login')
          })
    } catch (error) {
        navigate('/login')
    }
    
        setTransactionLoader(false);

    }


  const getAuthDetails = () =>{
    setFullName(localStorage.getItem('fullname'))
    setPhoneNumber(localStorage.getItem('phoneNumber'))
    setEmail(localStorage.getItem('email'));
    setProfilePic(localStorage.getItem('profilePic'))
    setssoId(localStorage.getItem('ssoId'))
    setHasPromo(localStorage.getItem('hasPromo'))
  }



  const updateUserData = (e) =>{
    e.preventDefault();
    setLoaderToggle(true)
    // console.log({               
    //   'email':email,
    //   'phone_number':phoneNumber,
    //   'calling_code':callingCode
    // })
    setTimeout(function(){
        setPhoneToggle(false)
        setLoaderToggle(true)
        setTimeout(function(){
            axios.post(configs.apiUrl+'/register',{               
                'email':email,
                'phone_number':phoneNumber,
                'calling_code':callingCode
              },{headers:{
                  'ContentType':'application/json'
              }}).then(response => {
           console.log(response.data);
           if(response.status === 200){
              setEmail(response.data.email);
              setPhoneNumber(response.data.data.phone_number);
              localStorage.setItem('phoneNumber', response.data.data.phone_number)
              localStorage.setItem('hasPromo', response.data.data.has_promo)
              localStorage.setItem('profilePic', response.data.data.profile_pic);
              setProfilePic(response.data.data.profile_pic);

              showSuccess('Success', 'User Details Updated')
            }else{
               runErrorMechanism(response.data.message)
           }
       }); 
        }, 1000)
    }, 3000)
    setLoaderToggle(false)    
}

  const handleChange = (e) => {
   const { name, value } = e.target;

    if (name === "password") {
        setPassword(value);
    } 
    if (name === "email") {
        setEmail(value);
    } 

    if (name === "phoneNumber") {
      setPhoneNumber(value);
    } 

    if (name === "currentPassword") {
      setCurrentPassword(value);
    } 

    if (name === "newPassword") {
      setNewPassword(value);
    } 

    if (name === "repeatPassword") {
      setRepeatPassword(value);
    } 

    if (name === "accountNumber") {
      setAccountNo(value);
      } 

  }


const getLocalStorageItems = () => {
  // setCountryFlag(localStorage.getItem('countryFlag') ?? "");
  // setCallingCode(localStorage.getItem('ca') ?? "");
  setCountryCallingCodes(localStorage.getItem('callingCodes') ? JSON.parse(localStorage.getItem('callingCodes')) : undefined);
}
const setFailureMessage = (message) =>{
setMessage(message)
}

const runErrorMechanism = (response) =>{
setFailureMessage(response ??  "Something went wrong, contact Swiff Support")
setFailureToggle(true);

}

const showSuccess = (title, messageDetails) =>{
  setDynamicSuccessMesssage(title);
  setDynamicSuccessTitle(messageDetails);
  setTimeout(function(){
    setDynamicSuccessModalToggle(true)
  }, 1000);
  setDynamicSuccessModalToggle(false)
  setLoaderToggle(false)    

}

useEffect(() => {
  if (!selectedFile) {
      setPreview(undefined)
      return
  }

  const objectUrl = URL.createObjectURL(selectedFile)
  setPreview(objectUrl)

  // free memory when ever this component is unmounted
  return () => URL.revokeObjectURL(objectUrl)
}, [selectedFile])

const onSelectFile = e => {
  if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
  }

  // I've kept this example simple by using the first image instead of multiple
  setSelectedFile(e.target.files[0])
}

const clickFileChooser = () =>{
  const fileChooser = document.getElementById('fileChooser').click();
}

const handleCountrySelect = (code,flag) =>{
  setCallingCode(code);
  setCountryFlag(flag)
  setToggle(!toggle);
}

const sendProfileImage = async() => {
  if(selectedFile){
    const formData = new FormData() 
    formData.append('image', selectedFile);
    formData.append('email', email);
    setLoaderToggle(true)
    const baseurl = `${configs.apiUrl}/upload/profile/pic`
    await axios.post(baseurl, formData, {headers:{
      'ContentType':'application/json',
      'Authorization':'Bearer '+localStorage.getItem('token')}})
    .then((response) => {
      setLoaderToggle(false)
      console.log(response.data);
        if(response.status === 200 ){
            let imageName = response.data.image; 
            console.log(imageName)
            localStorage.setItem('profilePic', imageName);
            setProfilePic(imageName);
            
          }else{
            return false;
          }
      setLoaderToggle(false)
    }) 
  }
  
}
const getLogo = (logo) => {
  if(logo){
    const splitLogo = logo.split(',')[2];
    return splitLogo;
  }
 

}

const togglePassword =()=>{
  if(passwordType==="password")
  {
   setPasswordType("text")
   return;
  }
  setPasswordType("password")
}

const handlePasswordChange =(e)=>{
  e.preventDefault();
    if(newPassword != repeatPassword){
      setFailedValidation(true)
      setFailedValidationMessage("Your new and current passwords don't match");
      return 0;
    }else{
        setTimeout(function(){
            setLoaderToggle(true)
        }, 1000)
        e.preventDefault();
        setTimeout(function(){
            setLoaderToggle(true)
            setTimeout(function(){
                axios.post(configs.apiUrl+'/change/password',{               
                    'email':email,
                    'current_password':currentPassword,
                    'new_password':newPassword
                  },{headers:{
                      'ContentType':'application/json',
                      'Authorization':'Bearer '+localStorage.getItem('token')
                  }}).then(response => {
               console.log(response.data);
               if(response.status === 200 && response.data.success){
                showSuccess('', 'Password Updated')
               }else{
                setLoaderToggle(false)
                setFailedValidation(true)
                setFailedValidationMessage(response.data.message);   
                setTimeout(function(){
                  setFailedValidation(false)
                },3000)
               }
           }); 
           setLoaderToggle(true)
            }, 1000)
            setLoaderToggle(true)
        }, 3000)
        setLoaderToggle(false)    
    }
}

const closeAndOpenModals = (toggle) => {
  if(toggle){
    setSuccessToggle(false);
  }  
  navigate('/profile')
 }


  return (
    <>
    <NavBar/>

        {
          loaderToggle && (
            <LoaderModal/>
            )
          }

        {
          failureToggle && (
            <FailedModal message={message} failureToggle={failureToggle} setFailureToggle={setFailureToggle}/>
            )
          }

          {
            dynamicSuccessModalToggle && (
              <DynamicSuccessModal message={dynamicSuccessMessage}  
              title={dynamicSuccessTitle}  
              dynamicSuccessModalToggle={dynamicSuccessModalToggle}
              setDynamicSuccessModalToggle={setDynamicSuccessModalToggle}
              />
            )
          }

               { 
                  promoToggle && (
                    <PromoModal
                    toggle={toggle}
                    promoToggle={promoToggle}
                    setToggle={setToggle}
                    code = {callingCode}
                    handleCountrySelect={handleCountrySelect}
                    countryFlag={countryFlag}
                    countryCallingCodes={countryCallingCodes}
                    phoneNumber={phoneNumber}
                    accountNumber={accountNo}
                    handleChange={handleChange}
                    setPromoToggle={setPromoToggle}
                    setLoaderToggle={setLoaderToggle}
                    setRecipientGets= {setRecipientGets}
                    setSendingToggle={setSendingToggle}
                    setSuccessToggle={setSuccessToggle}
                    runErrorMechanism={runErrorMechanism}
                    />
                  )
                }

                {
                  phoneToggle && (
                    <PhoneNumber 
                    toggle={toggle}
                    phoneToggle={phoneToggle}
                    code = {callingCode}
                    handleCountrySelect={handleCountrySelect}
                    setToggle={setToggle}
                    countryFlag={countryFlag}
                    countryCallingCodes={countryCallingCodes}
                    setCountryCallingCodes={setCountryCallingCodes}
                    setPhoneToggle={setPhoneToggle}
                    accountNumber={accountNo}
                    handleChange={handleChange}
                    updateUserData={updateUserData}
                    failedValidation={failedValidation}
                    failedValidationMessage={failedValidationMessage}
                    />
                  )
                }

      {
          sendingToggle && (
            <SendingModal code = {callingCode}  recipientGets={recipientGets} recipientNumber={accountNo}/>
          )
      }

      {
          successToggle && (
            <SuccessModal closeAndOpenModals={closeAndOpenModals} />
            )
          }

    <div className="main-profile-container">
        <div className="main-profile-wrapper">
          <div className='profile-section'>
            <div className='profile-section-header'>Profile</div>
            <div className='profile-section-imagename' >
             

              {selectedFile ?  
             
              <div className='profile-section-image'>
               <img onClick={clickFileChooser} className='profile-section-image' src={preview} /> 
              </div>
              : 
              (profilePic === 'null') ? 
                <div className='profile-section-image'>
                  <img className='profile-section-icon' onClick={clickFileChooser} src="favicon/camera.svg" alt="Ghana flag"/>
                </div> 
             
              : 
                <div className='profile-section-image'>
                  <img 
                   className='profile-section-image' 
                   style={{objectFit:"cover"}}
                   onClick={clickFileChooser} src={(ssoId != "") ? profilePic :  configs.imageUrl+localStorage.getItem('profilePic')} 
                   alt="Ghana flag"/>
                </div>
              }
              <div className="profile-section-username">
                <div className="profile-section-name">{fullName}</div>
                {ssoId === "" ? (
                  <div className="profile-section-edit" onClick={sendProfileImage}>Change Image</div>
                ):""
              
              }
              </div>
            </div>

            <div>
            <input style={{display:"none"}} id="fileChooser" type='file' onChange={onSelectFile} />
              
            </div>
            <form onSubmit={updateUserData}>
              <ul className='profile-input-fields'>
                  <li>
                      <input 
                      className="profile-input" 
                      type='email' 
                      placeholder='e.g Benjaminvil@gmail.com'
                      defaultValue={localStorage.getItem('email')}
                      disabled
                      name="email"
                      required
                      onChange={handleChange}
                      />
                  </li>
                  <li>
                      <input 
                      className="profile-input" 
                      type='text' 
                      placeholder='e.g 0204416108'
                      defaultValue={phoneNumber}
                      name="phoneNumber"
                      required
                      onChange={handleChange}
                      />
                  </li>
              </ul>

              {/*Update Button*/}
              <div className='profile-modal-ctas'>
                  <input className='profile-primary-cta' type="submit" value="Save Changes"/>
              </div>
            </form>
          </div>
          {
            hasPromo === "1"  ?
              <div className='promo' onClick={() => setPromoToggle(!promoToggle)}>
               {/* <div className='promo'> */}
                <div className='promo-wrap'>
                  <div className='giftbox-wrap'>
                    <img className="giftbox-gif" src="../../../gifs/giftbox.gif" alt="Hamburger-icon"/>
                    <div className='promo-message' >Claim your gift</div>
                  </div>
                  <div className='promo-cta'>Redeem</div>
                </div>
              </div>
            : ""
          }
          

          <div className='history-section'>
            <div className='history-section-header-wrap'>
              <div className='history-section-header'>History</div>
              <div className='history-section-all'>({transactions.length}) See all</div>
            </div>
            {transactionLoader &&(
            <img className="loader-gif" src="../../../gifs/swiff-loader.gif" alt="swiff-icon"/>
          )}
           {
             !transactionLoader && (
              transactions && transactions.map((trans) => {
                return(
                  <div className='history-transaction-rows'>
                  <ul key={trans.externalReferenceId}  className='history-transaction-details'>
                    <li>
                      <img className='product-network'
                        style={{height:"40px", width:"40px", objectFit:"cover", borderRadius:"50%"}} 

                       src={getLogo(trans.logos)} 
                       alt="Ghana flag"/>
                      <div className='history-details-bold'>{trans.date_created}</div>
                    </li>
                    <li>
                      <div>Recipient</div>
                      <div className='history-details-bold'>{trans.recipient_no}</div>
                    </li>
                    <li>
                      <div>{trans.transaction_service_type}</div>
                      <div className='history-details-bold'>{trans.recipient_gets}</div>
                    </li>
                    <li>
                      <div>Amount</div>
                      <div className='history-details-bold'>{trans.usd_price}</div>
                    </li>
                    <li>
                      <div>Status</div>
                      <div className='history-details-bold'>{trans.status}</div>
                    </li>
                    <li>
                      <div>Transaction ID</div>
                      <div className='history-details-bold'>{trans.transactionId}</div>
                    </li>
                    {/*Update Button*/}
                    {/* <div className='profile-modal-ctas-small'>
                        <input className='profile-primary-cta-small' type="submit" value="Download PDF"/>
                    </div> */}
                  </ul>
                </div>
                  )
                })
               )
             }

          {!transactions &&(
            <p className="loader-gif"  alt="swiff-icon">No Transactions</p>
          )}
           
           
          </div>




          <div className="Security-section">
            <form onSubmit={handlePasswordChange}>
              <div className="signup-password">
                  <input 
                  className="signup-password-input" 
                  placeholder='Current Password'
                  type={passwordType} 
                  name="currentPassword"
                  required
                  defaultValue={currentPassword}
                  onChange={handleChange}
                  />
                  { passwordType === "password" ? 
                  <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/hide.svg"   alt="hide Icon"/> :
                  <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/show.svg"   alt="hide Icon"/> }
              </div>
              <div className="signup-password">
                  <input 
                  className="signup-password-input" 
                  type={passwordType}
                  placeholder='New Password'
                  minLength={6}
                  name="newPassword"
                  required
                  defaultValue={newPassword}
                  onChange={handleChange}
                  />
                  { passwordType === "password" ? 
                  <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/hide.svg"   alt="hide Icon"/> :
                  <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/show.svg"   alt="hide Icon"/> }
              </div>
              <div className="signup-password">
                  <input 
                  className="signup-password-input" 
                  type={passwordType}
                  placeholder='Repeat Password'
                  name="repeatPassword"
                  required
                  minLength={6}
                  defaultValue={repeatPassword}
                  onChange={handleChange}
                  />
                  { passwordType === "password" ? 
                  <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/hide.svg"   alt="hide Icon"/> :
                  <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/show.svg"   alt="hide Icon"/> }
              </div>
              {
                      failedValidation && (
                          <span style={{
                              fontSize:"12px", 
                              float:"right",
                              color:"red",
                              paddingBottom:"10px",
                              paddingRight:"12px",
                          }}>{failedValidationMessage}</span>
                      )
                  }

              {/*Update Button*/}
              <div className='profile-modal-ctas'>
                  <input className='profile-primary-cta' type="submit" value="Save Changes"/>
              </div>
            </form>
          </div>
        </div>  
    </div>
    </>
  )
}
