import React, { useEffect, useState } from "react";
import { useNavigate, useParams} from 'react-router-dom';
import './Checkout.css';
import NavBar from '../../Components/NavBar';
import Footer from '../../Components/Footer';

import ReceiptModal from '../../Components/ReceiptModal';
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements} from "@stripe/react-stripe-js";
import { configs } from "../../Configs/app-configs";
import SuccessModal from '../../Components/SuccessModal';
import LoaderModal from '../../Components/LoaderModal';
import SendingModal from '../../Components/SendingModal';
import FailedModal from "../../Components/FailedModal";
import ReactGA from  'react-ga4';


export default function Checkout() {

  const [receiptToggle, setReceiptToggle] = useState(false);


  const [successToggle, setSuccessToggle] = useState(false);

  const [failureToggle, setFailureToggle] = useState(false);

  const [message, setMessage] = useState("");

  const [sendingToggle, setSendingToggle] = useState(false);

  const { code, accountNumber, purchaseTypeId} = useParams();

  const [loaderToggle, setLoaderToggle] = useState(false);
 
  const [countryCode, setCountryCode] = useState("233");

  const [clientSecret, setClientSecret] = useState("");

  const [paymentId, setPaymentId] = useState("");

  const [amount, setAmount] = useState("");

  const [accountNo, setAccountNo] = useState(accountNumber);

  const [recipientGets, setRecipientGets] = useState("");

  const [purchaseType, setPurchaseType] = useState("");

  const [productName, setProductName] = useState("");


  const [operatorId, setOperatorId] = useState(0);

  const navigate = useNavigate();
  const stripePromise = loadStripe(configs.stripePrivateKey);

  useEffect(() => {
    decodePurchaseItem()
    console.log(code, accountNumber);
    sendPaymentIntent();
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Checkout Page" });
    let dataObject =  JSON.parse(decodeURIComponent(purchaseTypeId))
    
    console.log(
      { 
        "operatorId":dataObject.id,
        "operatorDetails": dataObject,
        "currency": (localStorage.getItem('currency_symbol') === '' ) ? 'usd'  : localStorage.getItem('currency_symbol').toLowerCase(),
        "amount": dataObject.usd_price,
        "returnUrl": window.location.href,
        "code": code,
        "recipientNumber": accountNumber,
        "payment_type":"stripe",
        "description":"Payment for " + dataObject.recipient_gets + "/type/" + dataObject.type +"/id/"+dataObject.id,
        "bundle": localStorage.getItem('utilityBundle')

      }
    )
  }, [code,loaderToggle, accountNumber])
  
  const sendPaymentIntent = () =>{
    let dataObject =  JSON.parse(decodeURIComponent(purchaseTypeId))
       fetch(configs.apiUrl+"/transaction/send/payment", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            "operatorId":dataObject.id,
            "operatorDetails": dataObject,
            "currency": (localStorage.getItem('currency_symbol') === '' ) ? 'usd'  : localStorage.getItem('currency_symbol').toLowerCase(),
            "amount": dataObject.usd_price,
            "returnUrl": window.location.href,
            "code": code,
            "recipientNumber": accountNumber,
            "payment_type":"stripe",
            "description":"Payment for " + dataObject.recipient_gets + "/type/" + dataObject.type +"/id/"+dataObject.id 
          }),
        })
        .then((res) => res.json())
        .then((data) => {
          
          console.log(data.data);
          setClientSecret(data.data.clientSecret)
          setPaymentId(data.data.paymentId)
        });
  }
  
  const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };

    const decodePurchaseItem = () => {
      let dataObject =  JSON.parse(decodeURIComponent(purchaseTypeId))
      setAmount(dataObject.usd_price)
      setRecipientGets(dataObject.recipient_gets)
      setPurchaseType(dataObject.type === 'reloadly_utility' ? 'Utility' : dataObject.type)
      setOperatorId(dataObject.id)
      setProductName(dataObject.operator_name)
    }

    const closeAndOpenModals = (toggle) => {
     if(toggle){
      setSuccessToggle(false);
      if(localStorage.getItem('token') === null || localStorage.getItem('token')  === '')
        setReceiptToggle(true);
     }  
     navigate('/')
    }

    const setFailureMessage = (message) =>{
      setMessage(message)
    }

  

  return (
    <>
    <NavBar/>
        {
          receiptToggle && (
              <ReceiptModal receiptToggle={receiptToggle} setReceiptToggle={setReceiptToggle}/>
          )
        }

          {
          successToggle && (
            <SuccessModal closeAndOpenModals={closeAndOpenModals} />
            )
          }
           {
          failureToggle && (
            <FailedModal message={message} failureToggle={failureToggle} setFailureToggle={setFailureToggle}/>
            )
          }

         {
          loaderToggle && (
            <LoaderModal/>
            )
          }
         
          {
          sendingToggle && (
            <SendingModal code={code} recipientGets={recipientGets} recipientNumber={accountNumber}/>
          )
        }
     

    <div className='checkout'>
      <div className='checkout-wrapper'>
        <div className='payment-summary'>
          <div className='payment-summary-header'>Payment Summary</div>
          <ul className='payment-summary-details'>
            <li>
              <div>Recipient:</div>
              <div className='payment-summary-details-bold'>{localStorage.getItem('customRecipientNumber') ==='' ? '+'+code : ''}&nbsp;{accountNo}</div>
            </li>
            
            <li>
              <div>Product Type:</div>
              <div className='payment-summary-details-bold'>{productName}</div>
            </li>
            <li>
              <div>Recipient Gets:</div>
              <div className='payment-summary-details-bold'>{recipientGets}({purchaseType ?? localStorage.getItem('utilityBundle')})</div>
            </li>
          </ul>
          <div className='payment-summary-total'>
            <div>Total Amount:</div>
            <div>{localStorage.getItem('currency_symbol') ?? 'USD'} {amount}</div>
          </div>
          
          <div className='circle1'></div>
          <div className='circle2'></div>
          <div className='circle3'></div>
          <div className='circle4'></div>
        </div>

        
        <div className='stripe-placeholder'>
          <div className='stripe-placeholder-header'>Payment Method</div>
          {/*Placeholder for stripe codes*/}
          {!clientSecret &&(
            <img className="loader-gif" src="../../../gifs/swiff-loader.gif" alt="swiff-icon"/>
          )}

          {
            clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                  <PaymentForm 
                    paymentId={paymentId}
                    recipientGets={recipientGets}
                    recipientNumber={accountNo}
                    purchaseType={purchaseType}
                    code={code}
                    operatorId={operatorId}
                    failureToggle={failureToggle}
                    setSendingToggle={setSendingToggle}
                    setLoaderToggle={setLoaderToggle}
                    setSuccessToggle={setSuccessToggle} 
                    setFailureToggle={setFailureToggle}  
                    setFailureMessage={setFailureMessage}       
                  />
              </Elements>
          )
          }
          
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}
