import React from 'react'

function PrimaryButton({...props}) {
  return (
        <button  className='main-primary-button'>
            {props.name ?? 'Sign Up'}
        </button>   
    )
}

export default PrimaryButton