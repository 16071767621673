export const BackgroundConfigs = [
    {
        title: 'Who are you sending <br/> &nbsp; &nbsp; &nbsp; &nbsp;top up to?',
        backgroundColor:"#502274",
        h1:"#A97ACE"

    },
    {
        title: "&nbsp; &nbsp; Send Data <br/> top-up for any <br/> &nbsp; &nbsp; &nbsp; network",
        backgroundColor:"#4A2D1B",
        h1:"#D27C00"

    },
    {
        title: "Pay TV bills, so <br/>   they never miss <br/> &nbsp; &nbsp; &nbsp; a show",
        backgroundColor:"#4A2D1B",
        h1:"#733770"

    }
]
   
export const colorsCodes = [
    '#502274',
    '#5D3F27',
    '#733770'
  ];

  export const titleText = [
    'Who are you sending <br/> &nbsp; &nbsp; &nbsp; &nbsp;top up to?',
    '&nbsp; &nbsp; Send Data <br/> top-up for any <br/> &nbsp; &nbsp; &nbsp; network',
    'Pay TV bills, so <br/>   they never miss <br/> &nbsp; &nbsp; &nbsp; a show'
  ];

  export const titleColorsCodes = [
    '#DDBDFF',
    '#FFB835',
    '#FFA7C5'
  ];