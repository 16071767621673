import React, { useEffect } from 'react'

function UtilitiesDropdown({...props}) {

    
  return (
        <div className='product-dropdown'>
            <div className='product-dropdown-wrapper'>
                {/*Drop down rows*/}
                {props.utilities && props.utilities.map((utility) => {
                   return (
                        <div className='product-row' onClick={()=> props.setUtilitiesDetails(utility)}>
                        <div className='product' >
                            {
                                utility.logos != '' ? 
                                <img className="utilities-flag" src={utility.logos} alt="Ecg Meters"/>:
                                ''
                            }
                           
                            <div className="product-name">{utility.name}</div>
                        </div>
                    </div>
                    )
                   
                })}
                
               
            </div>
        </div> 
    )
}

export default UtilitiesDropdown