import NavBar from "../../Components/NavBar";
import React from "react";
import "./Support.css";
import SupportLink from "../../Components/SupportLink";


export default function Support(){
    return (
        <div className="wrapper flex_align_justify">
            <NavBar/>

            <div className="content">
                <h1>
                    Support
                </h1>

                <div className="img-center mt-1">
                    <img src="/gifs/support.gif" width="100" height="100" alt="Support" />
                </div>

                <p className="mt-1 bold sm">Reach us on</p>

                <div className="mt-2">
                    <SupportLink name="Phone" src="/images/phone_call.svg" linkTo="tel:+447769073040" />
                    <SupportLink name="WhatsApp" className="mt-2" src="/images/whatsapp.svg" linkTo="https://wa.me/message/QH7T7G72L6YXO1" />
                    <SupportLink name="Email" className="mt-2" src="/images/email.svg" linkTo="mailto:support@sendswiff.com" />
                </div>
            </div>
        </div>
    )
}