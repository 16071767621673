import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { configs } from '../Configs/app-configs';
import { getInitails } from '../Configs/function-configs';
import CurrencyDropdown from './CurrencyDropdown'
import ECGMetersDropdown from './ECGMetersDropdown';
import ProductDropdown from './ProductDropdown'
import TVBillsDropdown from './TVBillsDropdown';
import UtilitiesDropdown from './UtilitiesDropdown';
import VouchersDropdown from './VouchersDropdown';

export default function PurchaseNavbar({...props}) {
  const [currencyToggle, setCurrencyToggle] = useState(false);
  const [checkOutToggle, setCheckOutToggle] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate()


  useEffect(() => {
    getAuthDetails();
    console.log( props.utilitiesToggle, props.callingCode )
  }, [token])

  const getAuthDetails = () =>{
    setToken(localStorage.getItem('token'))
  }

  const signOut = () =>{
    console.log('here');

    axios.post(configs.apiUrl+'/logout',{                    
      },{headers:{
        'ContentType':'application/json',
        'Authorization':'Bearer '+token
          }}).then(response => {
      console.log(response.data);
      localStorage.setItem('token', 'null');
      //localStorage.setItem('token', null);

      navigate("/");
      window.location.href="/";
    }); 

    localStorage.setItem('token', "null");
    console.log("token",localStorage.getItem('token'));

  }

  

  return (
    <div className='navbar'>
    <div className='navbar-wrapper'>
    <Link to={'/'}>
      <img className="swiff-logo" src="../../favicon/swiff-logo.svg" alt="Swiff Logo"/>
    </Link>
      <div className='nav-ctas'>
          {
              
              localStorage.getItem('token') === "null" ||  localStorage.getItem('token') === null ? 
                
                <Link to={'/signup'}>
                <button className='signup-cta'>Sign Up</button>
                </Link>

                
                :

            

              <div style={{display:"flex", flexWrap:"space-between"}}>
              <Link to={'/profile'}>
                  <div className='profile-cirlce-wrap'>
                    <div className='.profile-cirlce'>{getInitails()}</div>
                  </div>
              </Link>
              <button onClick = {signOut} className='signup-cta'>Sign Out</button>
              </div>
            
            }
        <img class="hamburger-icon" style={{display:"none"}}src="../../favicon/hamburger.svg" alt="Hamburger-icon"/>
      </div>
    </div>
    <div className='navbar-wrapper2'>
      <div className='edit-and-curency'>
        <div className='edit-recipient' onClick={() => props.setEditToggle(!props.editToggle)}>
          <div className="flag-and-num">
            <img className="country-flag2"
             style={{height:"14px", width:"14px", objectFit:"cover", borderRadius:"50%"}} 
             src={props.countryFlag} alt="Ghana flag"/>
            <div className='recipient-num'>{props.code} {props.accountNumber}</div>
          </div>
          <div className="edit-icon">
            <img className="country-flag" src="../../favicon/edit.svg"  alt="Edit Icon"/>
          </div>
        </div>
        <div className="select-currency" onClick={() => setCurrencyToggle(!currencyToggle)}>
          <div className="currency">{props.currencyFilter}</div>
          <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
          {currencyToggle && (
             <CurrencyDropdown handleCurrencySelect = {props.handleCurrencySelect}/>
          )}

        </div>

      </div>
      <div className='select-product' onClick={() => setCheckOutToggle(!checkOutToggle)}>
        <div className='selected-product'>{props.purchaseType}</div>
        <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
        {checkOutToggle && (
        <ProductDropdown  handlePurchaseTypesSelect= {props.handlePurchaseTypesSelect}/>
        )}
      </div>
        {
            props.utilitiesToggle && (
              <div className='select-utility' onClick={() => props.setUtilitiesListToggle(!props.utilitiesListToggle)}>
                <div className='selected-product'>{props.utilitiesLable}</div>
                <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>  
                {props.utilitiesListToggle && (
                  <UtilitiesDropdown setUtilitiesDetails={props.setUtilitiesDetails} utilities={props.utilities}/>
                )}       
              </div>

            )
          }
          {
            props.ecgToggle && (
              <div className='select-ecg' onClick={() => props.setECGMetersToggle(!props.ecgMetersToggle)}>
                <div className='selected-product'>{props.ecgMeterslabel}</div>
                <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
                 {props.ecgMetersToggle && (
                   <ECGMetersDropdown 
                   ecgMetersLabel = {props.ecgMetersLabel} 
                   setECGAccountDetails={props.setECGAccountDetails}
                   setECGMetersLabel={props.setECGMetersLabel} 
                   ecgMeters={props.ecgMeters} />
                 )} 
              </div>
      
            )
          }

        {
            props.tvBillsToggle && (
              <div className='select-tv' onClick={() => props.setTvBillsDropdown(!props.tvBillsDropdown)}>
                <div className='selected-product'>{props.tvBillLabel}</div>
                <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
                 {props.tvBillsDropdown && (
                   <TVBillsDropdown
                   tvBillLabel = {props.tvBillLabel} 
                   tvBills={props.tvBills} 
                   setTvBillAccountDetails={props.setTvBillAccountDetails}
                   />
                 )} 
              </div>
      
            )
          }

          {
            props.voucherToggle && (
              <div className='select-tv' onClick={() => props.setVoucherTypesDropdown(!props.voucherTypesDropdown)}>
                <div className='selected-product'>{props.voucherLabel}</div>
                <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
                 {props.voucherTypesDropdown && (
                   <VouchersDropdown
                   voucherLabel = {props.voucherLabel} 
                   voucherTypes={props.voucherTypes} 
                   setVoucherDetails={props.setVoucherDetails}
                   />
                 )} 
              </div>
      
            )
          }


          {
            props.utilitiesToggle && props.callingCode === '234'  ?

                <ul className='utility-acc-input-fields'>
                    <li>
                        <input 
                        className="utility-acc-signup-input" 
                        type='text' 
                        placeholder='Account Number'
                        name="ngnUtilityAccNumber"
                        required
                        defaultValue={props.ngnUtilityAccNumber}
                        onChange={props.handleChange}
                        />
                    </li>
                    <span style={{
                              fontSize:"12px", 
                              float:"right",
                              color:"red",
                              paddingTop:"5px",
                              paddingRight:"12px",
                          }}>Required</span>
                </ul>
                
                :'' 
            }

          {
            props.tvBillsToggle ?
               
                <ul className='tv-acc-input-fields'>
                   <form onSubmit={props.getPayTvAccount} >
                      <li>
                            <input 
                            className="utility-acc-signup-input" 
                            type='text' 
                            placeholder='Account Number'
                            name="tvAccNumber"
                            required
                            defaultValue={props.tvAccNumber}
                            onChange={props.handleChange}
                            />
                        </li>
                        <div className='tv-acc-input-form-wrapper'>
                          <li><span>{props.tvAccount}</span></li>
                          <li><input className='tv-primary-cta' type="submit" value="Check"/></li> 
                        </div>
                         
                        
                       

                   </form>
                   
                </ul>

                
                
                :'' 


            }
    </div>
  </div> 
  )
}
