import React from 'react'
import PrimaryButton from './PrimaryButton'
import SecondaryButton from './SecondaryButton'

function SideNav() {
  return (
        <div className='sidebar-container'>
            <div className='sidebar'>
                <div className='sidebar-wrapper'>
                    <div className='sidebar-close'>
                        <img className="sidebar-close-icon" src="favicon/cancel.svg" alt="Close Icon"/>
                    </div>
                    <div className='sidebar-product-list'>
                        <div className='sidebar-product-row'>
                            <div className='sidebar-product'>
                                <img className="sidebar-product-icon" src="favicon/data-black.svg" alt="Ghana icon"/>
                                <div className="sidebar-product-name">Buy Data</div>
                            </div>
                        </div>
                        <div className='sidebar-product-row'>
                            <div className='sidebar-product'>
                                <img className="sidebar-product-icon" src="favicon/airtime-black.svg" alt="Ghana icon"/>
                                <div className="sidebar-product-name">Buy Airtime</div>
                            </div>
                        </div>
                        <div className='sidebar-product-row'>
                            <div className='sidebar-product'>
                                <img className="sidebar-product-icon" src="favicon/utility-black.svg" alt="Ghana icon"/>
                                <div className="sidebar-product-name">Pay Utility Bills</div>
                            </div>
                        </div>
                        <div className='sidebar-product-row'>
                            <div className='sidebar-product'>
                                <img className="sidebar-product-icon" src="favicon/tv-bills-black.svg" alt="Ghana flag"/>
                                <div className="sidebar-product-name">Pay TV Bills</div>
                            </div>
                        </div>
                    </div>
                    <div className='sidebar-cta'>
                        <div className='sidebar-cta-wrapper'>
                            <div className='sidebar-cta-signup'><PrimaryButton/></div>
                            <div className='sidebar-cta-login'><SecondaryButton/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default SideNav