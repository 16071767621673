import React, { useEffect } from 'react'

function TVBillsDropdown({...props}) {

  return (
        <div className='product-dropdown' style={{zIndex:0}}>
            <div className='product-dropdown-wrapper'>
                {/*Drop down rows*/}
                {props.tvBills && props.tvBills.map((bill) => {
                   return (
                        <div className='product-row'>
                        <div className='product' onClick={() => props.setTvBillAccountDetails(bill)}>
                            <div className="product-name">{bill.name}</div>
                        </div>
                    </div>
                    )
                   
                })}
                
               
            </div>
        </div> 
    )
}

export default TVBillsDropdown