import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, Link, useParams} from 'react-router-dom';
import axios from "axios";
import './Purchase.css';
import EditModal from '../../Components/EditModal';
import PurchaseNavbar from '../../Components/PurchaseNavbar';
import Footer from '../../Components/Footer';
import { configs } from '../../Configs/app-configs';
import LoaderModal from '../../Components/LoaderModal';
import ReactGA from  'react-ga4';
import PromoModal from '../../Components/PromoModal';
import SendingModal from '../../Components/SendingModal';
import SuccessModal from '../../Components/SuccessModal';
import FailedModal from '../../Components/FailedModal';


export default function Purchase() {
  const { code, accountNumber } = useParams();

  const [currency, setCurrency] = useState("GHS");

  const [currencyFilter, setCurrencyFilter] = useState("USD");

  const [purchaseType, setPurchaseType] = useState("Buy Data");

  const [purchaseTypeCode, setPurchaseTypeCode] = useState("data");

  const [purchaseTypeToggle, setPurchaseTypeToggle] = useState(false);

  const [countryFlag, setCountryFlag] = useState("");

  const [callingCode, setCallingCode] = useState(code);

  const [toggle, setToggle] = useState(false);

  const [loaderToggle, setLoaderToggle] = useState(false);

  const [editToggle, setEditToggle] = useState(false);

  const [accountNo, setAccountNo] = useState(accountNumber);

  const [countryCallingCodes, setCountryCallingCodes] = useState([]);

  const [operators, setOperators] = useState([]);

  const [productType, setProductType] = useState("");



  //Promo dependencies
  const [promoToggle, setPromoToggle] = useState(false);

  const[recipientGets, setRecipientGets] = useState("");

  const [sendingToggle, setSendingToggle] = useState(false);

  const [successToggle, setSuccessToggle] = useState(false);

  //failure dependencies
  const [failedValidation, setFailedValidation] = useState(false);
  const [failedValidationMessage, setFailedValidationMessage] = useState("");
  const [failureToggle, setFailureToggle] = useState(false);
  const[message, setMessage] = useState("");

  //Utility dependencies
  const [utilitiesToggle, setUtilitiesToggle] = useState(false);
  const [utilitiesListToggle, setUtilitiesListToggle] = useState(false);
  const [utilities, setUtilities] = useState([]);
  const [utilitiesLable, setUtilitiesLabel] = useState("");

  //(ECG) dependencies
  const [ecgToggle, setECGToggle] = useState(false);
  const [ecgMeters, setECGMeters] = useState([]);
  const [ecgMetersToggle, setECGMetersToggle] = useState(false);
  const [ecgMeterslabel, setECGMetersLabel] = useState("");
  const [ecgMetersAccount, setECGMeterAccount] = useState("");

  //Nigeria Utility Dependencies
  const [ngnUtilityAccToggle, setNGNUtilityAccountToggle] = useState(false);
  const [ngnUtilityAccNumber, setNGNUtilityAccNumber] = useState('');


  //Ghana PayTv
  const [tvBills, setTvBills] = useState([]);
  const [tvBillLabel, setTvBillLabel] = useState("")
  const [tvBillsToggle, setTvBillsToggle] = useState(false)
  const [tvAccNumber, setTvAccNumber] = useState("");
  const [tvBillsDropdown, setTvBillsDropdown] = useState(false);
  const [tvBillTypeCode, setTvBillTypeCode] = useState("")
  const [tvAccount, setTvAccount] = useState("")

  //Vouchers
  const [vouchers, setVouchers] = useState([]);
  const [voucherLabel, setVoucherLabel] = useState('');
  const [voucherToggle, setVoucherToggle] = useState(false);
  const [voucherTypes, setVoucherTypes] = useState([]);
  const [voucherTypesDropdown, setVoucherTypesDropdown] = useState(false);



  

  const count = useRef(0);


  useEffect(() => {
    window.location.href.replace(window.location.search, '');
    localStorage.setItem('customRecipientNumber', '')
    getLocalStorageItems();  
    setCallingCode(code)
    getOperators(null);
    setProductType('data');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Purchase Page" });
  }, [code, accountNumber])


  useEffect(
    () => {
      if(operators.length > 0 && localStorage.getItem('currencyCode') && localStorage.getItem('currencyCode') === 'GBP'){
        console.log('Length', operators.length);
          handleCurrencySelect("GBP")
        
    }else{
      console.log('Length Bad')
      localStorage.setItem('currency_symbol', currencyFilter);
    }
    },
    [operators],
  )
  

    const getOperators = async(type) => {
      setCurrencyFilter('USD');
      setUtilitiesToggle(false);
      setTvBillsToggle(false);
      setTvBillsDropdown(false);

      let typeData = type ? type : purchaseTypeCode
      setLoaderToggle(true);
      await axios.get(configs.apiUrl+"/transaction/get/operators/"+callingCode+"/"+accountNo+"/"+typeData)
            .then((response) => {
              if(response.status == 200 && response.data.data.success == true ){
                console.log("Run");
                setOperators(response.data.data.operators)              
                setLoaderToggle(false);
              }else{
                console.log("Failed");
                setOperators([]);
                runErrorMechanism(response.data.data.message ?? '')
              }
          })

          
          setLoaderToggle(false);

      }

      const getECGMeters = async() => {
      setCurrencyFilter('USD');
      setLoaderToggle(true);
      const resp =  await axios.get(configs.apiUrl+"/transaction/services/ecg_pre_post_paid/"+accountNo)
             .then((response) => {
               if(response.status == 200 && response.data.success == true ){
                 setECGMeters(response.data.data)              
                 setLoaderToggle(false);
                 console.log(response.data.data[0])
                 setECGMetersLabel(response.data.data[0].Display ?? "-----");
                 localStorage.setItem('utilityBundle', response.data.data[0].Value)
                 setECGToggle(true)
                 return true;
               }else{
                runErrorMechanism(response.data.data+", Please use your ECG PowerApp number")
                setECGToggle(false);
                setOperators([])
                return false;

               }
          });
          setLoaderToggle(false);

      return resp;
  
      }

      const getUtilityList = async() => {
        setLoaderToggle(true);
        setOperators([]);
        const utilityType = getUtilityTypeBasedOnCountry();
        const resp =  await axios.get(configs.apiUrl+"/transaction/operators/"+code+"/"+accountNumber+"/"+utilityType)
               .then((response) => {
                 if(response.status == 200 && response.data.data.success == true ){
                   setUtilities(response.data.data.operators)
                   if(response.data.data.operators.length > 0){
                    runECGMeterQuery(response.data.data.operators)
                   }
                   setUtilityOperators(response.data.data.operators[0].id)             
                   setLoaderToggle(false);
                   setUtilitiesLabel(response.data.data.operators[0].name ?? "-----");
                   setUtilitiesToggle(true)
                   setOperators(response.data.data.operators[0].operator_id_types)
                   
                   return true;
                 }else{
                  runErrorMechanism(response.data)
                  setUtilitiesToggle(false)
                  setECGToggle(false);
                  return false;
  
                 }
            });
            setLoaderToggle(false);
  
        return resp;
    
      }

      const getPayTvList = async() => {
        setLoaderToggle(true);
        setOperators([]);
        setTvBillsToggle(false)
        setTvBillsDropdown(false);
        const resp =  await axios.get(configs.apiUrl+"/transaction/operators/"+code+"/"+accountNumber+"/paytv")
               .then((response) => {
                 if(response.status == 200 && response.data.data.success == true ){
                  console.log(response.data.data)
                  setTvBills(response.data.data.operators)
                  setOperators(response.data.data.operators[0].operator_id_types)
                  setTvBillLabel(response.data.data.operators[0].name)
                  setTvBillTypeCode(response.data.data.operators[0].product_service?.service_code)
                  setTvBillsToggle(true)
                   return true;
                 }else{
                  setTvBillsToggle(false)
                  runErrorMechanism(response.data)
                 
                  return false;
  
                 }
            });
            setLoaderToggle(false);
  
        return resp;
    
      }

      const getVoucherTypeList = async() => {
        setLoaderToggle(true);
        setOperators([]);
        setVoucherToggle(false);
        setVoucherTypesDropdown(false);
        const resp =  await axios.get(configs.apiUrl+"/transaction/operators/"+code+"/"+accountNumber+"/voucher")
               .then((response) => {
                 if(response.status == 200 && response.data.data.success == true ){
                  console.log(response.data.data)
                  setVoucherTypes(response.data.data.operators)
                  setOperators(response.data.data.operators[0].operator_id_types)
                  
                  setVoucherLabel(response.data.data.operators[0].name)
                  setVoucherToggle(true)
                   return true;
                 }else{
                  setVoucherToggle(false)
                  runErrorMechanism(response.data)
                 
                  return false;
  
                 }
            });
            setLoaderToggle(false);
  
        return resp;
    
      }

  const setUtilitiesDetails =(utility)=>{
    setUtilitiesLabel(utility.name)
    setUtilityOperators(utility.id)
  }

  const setECGAccountDetails = (account) =>{
    setECGMeterAccount(account.Value)
    setECGMetersLabel(account.Display + 'GHS ' +account.Amount)
    localStorage.setItem('utilityBundle', account.Value)
  }

  const setTvBillAccountDetails = (bill) => {
    setTvAccount("")
    setTvBillLabel(bill.name)
    setTvBillTypeCode(bill.product_service?.service_code)
    setTvBillOperators(bill.id)
    //show Account Number Field
  }

  const setVoucherDetails = (voucherType) => {
    console.log(voucherType)
    setVoucherLabel(voucherType.name)
    setVoucherOperators(voucherType.id)
  }

  const setUtilityOperators = async (id) => {
    console.log(id, utilities)
      for(var i = 0; i < utilities.length; i++ ){
        if(utilities[i].id == id){
         if(utilities[i].name.includes('ECG')){
          if(callingCode === '233'){
            //get ghanaian utility list
            getECGMeters();  
          }
          if(callingCode === '233'){
              console.log('set tv operators')
              setOperators(utilities[i].operator_id_types)
          }

          if(callingCode === '234'){
            //get nigerian utility Account number
            
          }
         }else{
          console.log('does not includes')
          setECGToggle(false)
         }
          setOperators(utilities[i].operator_id_types)
        }
      }
  }

  const setTvBillOperators = async (id) => {
    console.log(id, tvBills)
      for(var i = 0; i < tvBills.length; i++ ){
        if(tvBills[i].id == id){
          setOperators(tvBills[i].operator_id_types)   
      }
    }
  }

  const setVoucherOperators = async (id) => {
    console.log(id, vouchers)
      for(var i = 0; i < vouchers.length; i++ ){
        if(vouchers[i].id == id){
          setOperators(vouchers[i].operator_id_types)   
      }
    }
  }

  const runECGMeterQuery = (utilities) => {
    for(let i = 0; i < utilities.length; i++){
      setUtilitiesDetails(utilities[i])
      if(utilities[i].name.includes('ECG')){
        if(callingCode === '233'){
          //get ghanaian utility list
          getECGMeters();  
        }
      }
    }
  }

  const encodePurchaseItem = (data) => {
    return encodeURIComponent(JSON.stringify(data))
  }

  const getLocalStorageItems = () => {
    setCountryFlag(localStorage.getItem('countryFlag') ?? "");
    setCountryCallingCodes(localStorage.getItem('callingCodes') ? JSON.parse(localStorage.getItem('callingCodes')) : undefined);
  }

  const getLogo = (logo) => {
    console.log("logo: ",logo);
    if(logo.includes(',')){
      return logo.split(',')[2];
    }
    return logo;

  }

  const handlePurchaseTypesSelect = (value) =>{
    setPurchaseType(value);
    setTimeout(function(){
      setPurchaseTypeToggle(!purchaseTypeToggle);
      setPurchaseTypeItem(value)
    }, 500)
  }
  const setPurchaseTypeItem = async (value) => {
    setECGToggle(false)
    setUtilitiesToggle(false);
    setTvBillsToggle(false)
    setTvBillsDropdown(false);
    setVoucherToggle(false)
    setTvAccount("")
    switch(value){
      case "Buy Data":
        setPurchaseTypeCode("data");
        getOperators("data");
        setProductType("data");
      break;
      case "Buy Airtime":
        setPurchaseTypeCode("airtime");
        getOperators("airtime");
        setProductType("airtime");

      break;
      case "Pay Utility Bills":
        setPurchaseTypeCode("utility");
        setProductType("utility");
        getUtilityList()
      break;
      case "Pay TV Bills":
        setPurchaseTypeCode("paytv");
        setProductType("tv");
        getPayTvList();
      break;
      case "Send a Virtual Gift":
        setProductType("gift");
        setPurchaseTypeCode("gift");
        getOperators("gift");
      break;
      case "Send Gift Voucher":
        setPurchaseTypeCode("voucher");
        setProductType("voucher");
        getVoucherTypeList();
      break;
    }
  }

  const getUtilityTypeBasedOnCountry = () => {    
    let utilityType;
    switch (callingCode) {
      case '233':
        utilityType = 'billpayment';
      break;
      case '234':
        utilityType = 'reloadly_utility';
      break;
    
      default:
        utilityType= ''
        break;
    }
    return utilityType;
  }

  const handleCountrySelect = (code,flag) =>{
    setCallingCode(code);
    setCountryFlag(flag)
    setToggle(!toggle);

  }

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    localStorage.setItem('customRecipientNumber', '')

    if (name === "accountNumber") {
        setAccountNo(value);
      } 
    if (name === "ngnUtilityAccNumber") {
        localStorage.setItem('customRecipientNumber', value);
        setAccountNo(value);
        setNGNUtilityAccNumber(value);
    } 
    if(name === "tvAccNumber"){
      localStorage.setItem('customRecipientNumber', value);
      setTvAccNumber(value)
    }
  }

  const handleCurrencySelect = (value) =>{
    setCurrencyFilter(value);
    handleCurrencyPrice(value);
    localStorage.setItem('currency_symbol', value);
  }

  const closeAndOpenModals = (toggle) => {
    if(toggle){
      setSuccessToggle(false);
    }  
    window.location.reload()
   }
  
   const getPayTvAccount = async(e) =>{
    e.preventDefault()
    if(tvAccNumber.length > 0){
      setLoaderToggle(true);
      const resp =  await axios.get(configs.apiUrl+"/transaction/services/"+tvBillTypeCode+"/"+tvAccNumber)
             .then((response) => {
               if(response.status == 200 && response.data.success == true ){
                const tvAccountData = response.data.data
                setTvAccount(tvAccountData[0].Value+"("+tvAccountData[1].Value+")")
                 return true;
               }else{
                runErrorMechanism(response.data.data)
               
                setOperators([])
                return false;

               }
          });
          setLoaderToggle(false);

      //return resp;
  
    }else{
      runErrorMechanism('Please enter a tv account number')
    }
   }

  const handleCurrencyPrice = (value) => {
    for(let i = 0; i < operators.length; i++){
      for(let j = 0; j < operators[i].currency_conversions.length; j++){
   
        if(operators[i].currency_conversions[j].currency_symbol === value){
          
          operators[i].usd_price = operators[i].currency_conversions[j].currency_conversion;
          console.log(value, operators[i].usd_price);
        }
    };
    }
    
  }


const setFailureMessage = (message) =>{
  setMessage(message)
  }

  const runErrorMechanism = (response) =>{
    setFailureMessage(response ?? "Something went wrong, contact Swiff Support")
    setFailureToggle(true);
   
    }

  return (
    <div>
      {
        loaderToggle && (
          <LoaderModal/>
        )
      }
     
      {
        editToggle && (
          <EditModal
          toggle={toggle}
          setToggle={setToggle}
          code = {callingCode}
          handleCountrySelect={handleCountrySelect}
          countryFlag={countryFlag}
          countryCallingCodes={countryCallingCodes}
          accountNumber={accountNo}
          handleChange={handleChange}
          setEditToggle={setEditToggle}
          />
        )
      }

      <PurchaseNavbar
       accountNumber={accountNumber}
       code={code}
       callingCode={callingCode}
       editToggle={editToggle}
       utilitiesToggle={utilitiesToggle}
       utilities={utilities}
       utilitiesLable={utilitiesLable}
       tvBills={tvBills}
       tvBillLabel={tvBillLabel}
       tvBillsToggle={tvBillsToggle}
       tvBillsDropdown={tvBillsDropdown}
       utilitiesListToggle={utilitiesListToggle}
       ecgToggle = {ecgToggle}
       ecgMeters = {ecgMeters}
       ecgMetersToggle = {ecgMetersToggle}
       purchaseType={purchaseType}
       currencyFilter={currencyFilter}
       countryFlag={countryFlag}
       tvAccNumber={tvAccNumber}
       ecgMeterslabel={ecgMeterslabel}
       ngnUtilityAccNumber={ngnUtilityAccNumber}
       ngnUtilityAccToggle={ngnUtilityAccToggle}
       tvAccount={tvAccount}
       voucherLabel={voucherLabel}
       voucherToggle={voucherToggle}
       voucherTypes={voucherTypes}
       voucherTypesDropdown={voucherTypesDropdown}
       getPayTvAccount={getPayTvAccount}
       handleChange={handleChange}
       setEditToggle={setEditToggle}
       setUtilitiesLabel={setUtilitiesLabel}
       setUtilitiesToggle = {setUtilitiesToggle}
       setUtilitiesListToggle={setUtilitiesListToggle}
       setUtilitiesDetails={setUtilitiesDetails}
       setTvBillAccountDetails={setTvBillAccountDetails}
       setECGToggle={setECGToggle}
       setECGMetersToggle={setECGMetersToggle}
       setECGMetersLabel = {setECGMetersLabel}
       setECGAccountDetails={setECGAccountDetails}
       setNGNUtilityAccountToggle={setNGNUtilityAccountToggle}
       setTvBillLabel={setTvBillLabel}
       setTvBillsToggle={setTvBillsToggle}
       setTvBillsDropdown={setTvBillsDropdown}
       setNGNUtilityAccNumber={setNGNUtilityAccNumber} 
       handleCurrencySelect={handleCurrencySelect}
       handlePurchaseTypesSelect={handlePurchaseTypesSelect}
       setVoucherDetails={setVoucherDetails}
       setVoucherTypesDropdown={setVoucherTypesDropdown}
      
      />

          { 
                  promoToggle && (
                    <PromoModal
                    toggle={toggle}
                    promoToggle={promoToggle}
                    setToggle={setToggle}
                    code = {callingCode}
                    handleCountrySelect={handleCountrySelect}
                    countryFlag={countryFlag}
                    countryCallingCodes={countryCallingCodes}
                    accountNumber={accountNo}
                    handleChange={handleChange}
                    setPromoToggle={setPromoToggle}
                    setLoaderToggle={setLoaderToggle}
                    setRecipientGets= {setRecipientGets}
                    setSendingToggle={setSendingToggle}
                    setSuccessToggle={setSuccessToggle}
                    runErrorMechanism={runErrorMechanism}
                    />
                  )
                }

        {
          sendingToggle && (
            <SendingModal code = {callingCode}  recipientGets={recipientGets} recipientNumber={accountNo}/>
          )
      }

        {
          successToggle && (
            <SuccessModal closeAndOpenModals={closeAndOpenModals} />
            )
          }

  {
    failureToggle && (
      <FailedModal message={message} failureToggle={failureToggle} setFailureToggle={setFailureToggle}/>
      )
    }

      <div className='purchasepage'>
        <div className='purchasepage-wrapper'>
           
           {
             productType === 'data' && (
              operators && operators.map((op) => {
                return(
                       <Link key={op.id} to={"/checkout/"+code+"/"+accountNo+"/"+`${encodePurchaseItem(op)}`} >
                       <div className='data-purchase-card'>
                         <img className="data-product-logo" src={getLogo(op.logos)} alt="MTN Logo"/>
                         <div className='data-purchase'> {currencyFilter} {op.usd_price}</div>
                         <div className='data-purchase-cta'>
                           <div className='data-purchase-amount'>{op.recipient_gets}</div>
                           <img className="right-arrow" src="../../favicon/arrow-right.svg" alt="Arrow Right"/>
                         </div>
                       </div>
                     </Link>
                  )
              })
             )
           }


          {/* Promo card design */}
          {
             productType === 'airtime' && (
              operators && operators.map((op) => {
                return(
                    <div>                    
                       {
                         /* not logged in so redirect to profile */
                            op.is_promo === "1"  && localStorage.getItem('token') === "" ? 
                            <Link key={op.id} to={"/profile"} >
                              <div className='airtime-purchase-card'>
                                <div className='promo-special-card'>
                                  <img className="airtime-product-logo" src={getLogo(op.logos)} alt="MTN Logo"/>
                                  {
                                    op.is_promo === "1" ? 
                                    <img className="giftbox-gif gif-margin" src="../../../gifs/giftbox.gif" alt="giftbox-icon"/>
                                    : ""
                                  }
                                  
                                </div>
                                <div className='airtime-purchase'> {currencyFilter} {op.usd_price}</div>
                                <div className='airtime-purchase-cta'>
                                  <div className='airtime-purchase-amount'>{op.recipient_gets}</div>
                                  <img className="right-arrow" src="../../favicon/arrow-right.svg" alt="Arrow Right"/>
                                </div>
                              </div>
                            </Link>
                            : 
                            /* logged in so call PromoToggle */

                            op.is_promo === "1"  && localStorage.getItem('token') != "" &&  localStorage.getItem('hasPromo') === "1"? 
                            // onClick={() => setPromoToggle(!promoToggle)}
                           <Link key={op.id} to={"#"} onClick={() => setPromoToggle(!promoToggle)}>
                            <div className='airtime-purchase-card'>
                              <div className='promo-special-card'>
                                <img className="airtime-product-logo" src={getLogo(op.logos)} alt="MTN Logo"/>
                                {
                                  op.is_promo === "1" ? 
                                  <img className="giftbox-gif gif-margin" src="../../../gifs/giftbox.gif" alt="giftbox-icon"/>
                                  : ""
                                }
                                
                              </div>
                              <div className='airtime-purchase'> {currencyFilter} {op.usd_price}</div>
                              <div className='airtime-purchase-cta'>
                                <div className='airtime-purchase-amount'>{op.recipient_gets}</div>
                                <img className="right-arrow" src="../../favicon/arrow-right.svg" alt="Arrow Right"/>
                              </div>
                            </div>
                            </Link>
                          :
                            /* No Promo */

                          <Link key={op.id} to={"/checkout/"+code+"/"+accountNo+"/"+`${encodePurchaseItem(op)}`} >
                            <div className='airtime-purchase-card'>
                              <div className='promo-special-card'>
                                <img className="airtime-product-logo" src={getLogo(op.logos)} alt="MTN Logo"/>
                                
                                
                              </div>
                              <div className='airtime-purchase'> {currencyFilter} {op.usd_price}</div>
                              <div className='airtime-purchase-cta'>
                                <div className='airtime-purchase-amount'>{op.recipient_gets}</div>
                                <img className="right-arrow" src="../../favicon/arrow-right.svg" alt="Arrow Right"/>
                              </div>
                            </div>
                            </Link>
                          }
                      
                     </div>
                  )
              })
             )
           }

          {
             productType === 'utility' && (
              operators && operators.map((op) => {
                return(
                       <Link key={op.id} to={  callingCode === '234' && localStorage.getItem('customRecipientNumber') ==='' ? '#':"/checkout/"+code+"/"+accountNo+"/"+`${encodePurchaseItem(op)}`} >
                       <div className='utility-purchase-card'>
                         {
                           op.logos !='' ? 
                           <img className="utility-product-logo" src={getLogo(op.logos)} alt="MTN Logo"/>:
                           ''
                         }
                         
                         <div className='utility-purchase'> {currencyFilter} {op.usd_price}</div>
                         <div className='utility-purchase-cta'>
                           <div className='utility-purchase-amount'>{op.recipient_gets}</div>
                           <img className="right-arrow" src="../../favicon/arrow-right.svg" alt="Arrow Right"/>
                         </div>
                       </div>
                     </Link>
                  )
              })
             )
           }
         
          {
             productType === 'tv' && (
              operators && operators.map((op) => {
                return(
                  <Link key={op.id} to={  callingCode === '233' && localStorage.getItem('customRecipientNumber') ==='' ? '#':"/checkout/"+code+"/"+accountNo+"/"+`${encodePurchaseItem(op)}`} >
                      <div className='tv-purchase-card'>
                         <img className="tv-product-logo" src={getLogo(op.logos)} alt="MTN Logo"/>
                         <div className='tv-purchase'> {currencyFilter} {op.usd_price}</div>
                         <div className='tv-purchase-cta'>
                           <div className='tv-purchase-amount'>{op.recipient_gets}</div>
                           <img className="right-arrow" src="../../favicon/arrow-right.svg" alt="Arrow Right"/>
                         </div>
                       </div>
                     </Link>
                  )
              })
             )
           }

          {
             productType === 'voucher' && (
              operators && operators.map((op) => {
                return(
                  <Link key={op.id} to={"/checkout/"+code+"/"+accountNo+"/"+`${encodePurchaseItem(op)}`} >
                      <div className='voucher-purchase-card'>
                         {
                           op.logos !='' ? 
                           <img className="voucher-product-logo" src={getLogo(op.logos)} alt="MTN Logo"/>:
                           ''
                         }
                         <div className='voucher-purchase'> {currencyFilter} {op.usd_price}</div>
                         <div className='voucher-purchase-cta'>
                           <div className='voucher-purchase-amount'>{op.recipient_gets}</div>
                           <img className="right-arrow" src="../../favicon/arrow-right.svg" alt="Arrow Right"/>
                         </div>
                       </div>
                     </Link>
                  )
              })
             )
           }
        </div>
      </div>
      <Footer/>
    </div>
  )
}
