import React, { useEffect } from 'react'

function ECGMetersDropdown({...props}) {

  return (
        <div className='product-dropdown'>
            <div className='product-dropdown-wrapper'>
                {/*Drop down rows*/}
                {props.ecgMeters && props.ecgMeters.map((meters) => {
                   return (
                        <div className='product-row'>
                        <div className='product' onClick={() => props.setECGAccountDetails(meters)}>
                            <div className="product-name">{meters.Display}({'GHS ' +meters.Amount})</div>
                        </div>
                    </div>
                    )
                   
                })}
                
               
            </div>
        </div> 
    )
}

export default ECGMetersDropdown