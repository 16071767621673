import React from 'react'

function SendingModal({...props}){
  return (
    <div className="sending-modal">
        <div className="sending-modal-wrapper">
            <div className="sending-modal-title">Sending Swiffly</div>
            <div className="sending-modal-dynamic-text"><span className='dynamic-number'>+{props.code} {props.recipientNumber}</span> will receive <span className='dynamic-amount'>{props.recipientGets}</span></div>
            <div className="sending-gif-container">
                <img className="sending-gif" src="../../../gifs/sending-swiffly.gif" alt="Hamburger-icon"/>
            </div>
        </div>  
    </div>
    )
}

export default SendingModal