import React from "react";
import {Link} from "react-router-dom";

export default function ({...props}){
    return (
        <Link to={props.linkTo} target="_blank" >
            <div className={`flex ${props.className}`} >
                <img src={props.src} width="20" height="20" alt="Icon" />
                <h3 className="ml-1">{props.name}</h3>
            </div>
        </Link>
    );
}