import React, { useEffect, useState } from 'react'
import './Login.css';
import NavBar from '../../Components/NavBar';
import { Link, useNavigate } from 'react-router-dom';
import LoaderModal from '../../Components/LoaderModal';
import axios from 'axios';
import { configs } from '../../Configs/app-configs';
import FailedModal from '../../Components/FailedModal';
import ReactGA from  'react-ga4';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import DynamicSuccessModal from '../../Components/DynamicSuccessModal';




export default function Login() {
    const [loaderToggle, setLoaderToggle] = useState(false);
    const [failureToggle, setFailureToggle] = useState(false);
    const[message, setMessage] = useState("");
    const[email, setEmail] = useState("");
    const[password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [ user, setUser ] = useState([]);

    //dynamic success dependencies
    const [dynamicSuccessModalToggle, setDynamicSuccessModalToggle] = useState(false);
    const [dynamicSuccessTitle, setDynamicSuccessTitle] = useState("");
    const [dynamicSuccessMessage, setDynamicSuccessMesssage] = useState("");

    const navigate = useNavigate()

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Login Page" });
    }, [])

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            //console.log(codeResponse)
            setUser(codeResponse)
        
        },
        onError: (error) => console.log('Login Failed:', error)
    });
    
    const login = (e) =>{
        setTimeout(function(){
            setLoaderToggle(true)
        }, 1000)
        e.preventDefault();
        setTimeout(function(){
            setLoaderToggle(true)
            setTimeout(function(){
                axios.post(configs.apiUrl+'/login',{               
                    'email':email,
                    'password':password,
                    'ip': localStorage.getItem('ip'),
                    'country_calling_code': localStorage.getItem('countryCallingCode'),
                    'device_details': {
                        'browser_code_name': navigator.appCodeName,
                        'browser_name': navigator.appName,
                        'browser_version': navigator.appVersion,
                        'browser_language': navigator.language,
                        'browser_online': navigator.onLine,
                        'browser_platform': navigator.platform,
                        'browser_agent': navigator.userAgent
                    }
                  },{headers:{
                      'ContentType':'application/json'
                  }}).then(response => {
               console.log(response.data, response.status, response.data.success );
               setLoaderToggle(false)
               if(response.status === 200 && response.data.success){
                    setAuthDetails(response);
                    if(response.data.user.email_confirmed === '0'){
                        navigate("/");
                        //showSuccess('', 'An email has been sent to you for confirmation')
                    }else{
                        navigate("/");
                    }
               }else{
                   console.log("run Error");
                   runErrorMechanism(response)
               }
           }); 
           setLoaderToggle(false)
            }, 1000)
            setLoaderToggle(false)
        }, 3000)
        setLoaderToggle(false)    
    }

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        console.log(res.data)
                        loginWithoutPassword(res);
                        //setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );




    const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
        setPassword(value);
    } 
    if (name === "email") {
        setEmail(value);
    } 

    }

    const setFailureMessage = (message) =>{
    setMessage(message)
    }

    const runErrorMechanism = (response) =>{
        setFailureMessage(response.data.message ?? "Something went wrong, contact Swiff Support")
        setFailureToggle(true);
    }

    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }

    const setAuthDetails = (response) =>{
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('phoneNumber', response.data.user.phone_number)
        localStorage.setItem('fullname', response.data.user.full_name)
        localStorage.setItem('email', response.data.user.email)
        localStorage.setItem('ssoId', response.data.user.sso_id)
        localStorage.setItem('profilePic', response.data.user.profile_pic)
        localStorage.setItem('hasPromo', response.data.user.has_promo)
        localStorage.setItem('emailConfirmed', response.data.user.email_confirmed)
    }
    const loginWithoutPassword = (res) =>{
        setLoaderToggle(true)
        setTimeout(function(){
            setLoaderToggle(true)
            setTimeout(function(){
                axios.post(configs.apiUrl+'/loginWithoutPassword',{ 
                    'full_name':res.data.name,
                    'username':res.data.given_name,
                    'email':res.data.email,
                    'profile_pic':res.data.picture,
                    'sso_objects': res.data,
                    'sso_id':user.access_token,
                    'ip': localStorage.getItem('ip'),
                    'country_calling_code': localStorage.getItem('countryCallingCode'),
                    'device_details': {
                        'browser_code_name': navigator.appCodeName,
                        'browser_name': navigator.appName,
                        'browser_version': navigator.appVersion,
                        'browser_language': navigator.language,
                        'browser_online': navigator.onLine,
                        'browser_platform': navigator.platform,
                        'browser_agent': navigator.userAgent
                    }
                  },{headers:{
                      'ContentType':'application/json'
                  }}).then(response => {
               console.log(response.data);
               if(response.status === 200 && response.data.success){
                setAuthDetails(response);
                    if(response.data.user.email_confirmed === '0'){
                        //showSuccess('', 'An email has been sent to you for confirmation')
                        navigate("/");

                    }else{
                        navigate("/");
                    }      
               }else{
                   runErrorMechanism(response)
               }
           }); 
           setLoaderToggle(false)

            }, 1000)
            setLoaderToggle(true)
        }, 3000)
        setLoaderToggle(false)    

    }

    const showSuccess = (title, messageDetails) =>{
        setDynamicSuccessMesssage(title);
        setDynamicSuccessTitle(messageDetails);
        setTimeout(function(){
          setDynamicSuccessModalToggle(true)
        }, 1000);
        setDynamicSuccessModalToggle(false)
        setLoaderToggle(false)    
    
      }

  return (
    <>
    <NavBar/>
    
        {
          loaderToggle && (
            <LoaderModal/>
            )
          }

        {
          failureToggle && (
            <FailedModal message={message} failureToggle={failureToggle} setFailureToggle={setFailureToggle}/>
            )
          }
          {
            dynamicSuccessModalToggle && (
              <DynamicSuccessModal message={dynamicSuccessMessage}  
              title={dynamicSuccessTitle}  
              dynamicSuccessModalToggle={dynamicSuccessModalToggle}
              setDynamicSuccessModalToggle={setDynamicSuccessModalToggle}
              />
            )
          }
    <div className="signup-container">
        <div className="signup-wrapper">
            <form onSubmit={login}>
                <div className='signup-modal-nav'>
                    <div className='signup-modal-header'>Login</div>
                </div>
                <div className='signup-modal-subheader'>Welcome back! Log in to your Swiff account to stay connected with your loved ones Swiffly!</div>
                {/*Input and Dropdown section*/}
                <ul className='signup-input-fields'>
                    <li>
                        <input 
                        className="signup-input" 
                        type='email' 
                        placeholder='Email'
                        name="email"
                        required
                        defaultValue={email}
                        onChange={handleChange}
                        />
                    </li>
                    <li className="signup-password">
                        <input 
                        className="signup-password-input" 
                        type={passwordType} 
                        placeholder='Password'
                        name="password"
                        required
                        defaultValue={password}
                        onChange={handleChange}
                        />
                        { passwordType === "password" ? 
                        <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/hide.svg"   alt="hide Icon"/> :
                        <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/show.svg"   alt="hide Icon"/> }
                    </li>

                </ul>

                {/*Submit Button*/}
                <div className='signup-modal-ctas'>
                    <input className='signup-primary-cta' type="submit" value="Login"/>
                    <div className="signup-secondary-cta">
                        <img className="signup-google-icon" src="../../../images/google.png" alt="hide Icon"/>
                        <input className="signup-google-signup" onClick={() => loginGoogle()} type='submit' value="Login with Google"/>
                    </div>
                </div>
            </form> 
            {/* Redirect to Login up */}
            <div className='signup-redirect-cta'>
                Don’t have an account?
                <Link to={'/Signup'} >
                    <span className='signup-redirect-link'>Signup</span>
                </Link>
            </div>
        </div>  
    </div>
    </>
  )
}
