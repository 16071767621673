import React from 'react'

function CurrencyDropdown({...props}) {

  return (
    <div className='currency-dropdown'>
            <div className='currency-dropdown-wrapper'>
                {/*Drop down rows*/}
                {/* <div className='currency-row' onClick={() => props.handleCurrencySelect("GHS")}>
                    <div className='flag-code'>GHS</div>
                </div> */}
                {/* <div className='currency-row' onClick={() => props.handleCurrencySelect("NGN")}>
                    <div className='flag-code'>NGN</div>
                </div> */}
                 <div className='currency-row' onClick={() => props.handleCurrencySelect("EUR")}>
                    <div className='flag-code'>EUR</div>
                </div>
                <div className='currency-row' onClick={() => props.handleCurrencySelect("GBP")}>
                    <div className='flag-code'>GBP</div>
                </div>
                <div className='currency-row' onClick={() => props.handleCurrencySelect("USD")}>
                    <div className='flag-code'>USD</div>
                </div>
            </div>
        </div> 
    )
}

export default CurrencyDropdown