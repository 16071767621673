import React, { useEffect, useState } from 'react'
import SideNav from './SideNav'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { configs } from '../Configs/app-configs';
import { getInitails } from '../Configs/function-configs';
import ReactGA from  'react-ga';

function NavBar() {
 const [token, setToken] = useState("");
 
 const navigate = useNavigate()

 useEffect(() => {
   ReactGA.pageview(window.location.pathname);
   getAuthDetails();
 }, [token])
 
  const getAuthDetails = () =>{
    setToken(localStorage.getItem('token'))
    // localStorage.getItem('username')
    // localStorage.getItem('fullname')
    // localStorage.getItem('email')
  }

  const signOut = () =>{
    console.log('here');

    axios.post(configs.apiUrl+'/logout',{                    
      },{headers:{
        'ContentType':'application/json',
        'Authorization':'Bearer '+token
          }}).then(response => {
      console.log("token",localStorage.getItem('token'));
      localStorage.setItem('token', 'null');
      //localStorage.setItem('token', null);

      navigate("/");
      window.location.href="/";
    }); 
    //localStorage.setItem('token', null);
    localStorage.setItem('token', "null");
    console.log("token",localStorage.getItem('token'));

  }
  return (
    <>
        <div className='navbar'>
          <div className='navbar-wrapper'>
            <Link to={'/'}>
              <img className="swiff-logo" src="../../../favicon/swiff-logo.svg" alt="Swiff Logo"/>
            </Link>

            <div className='nav-ctas'>
              
             
              {
              // localStorage.getItem('token') !="" && localStorage.getItem('emailConfirmed') === '1' ? 
              localStorage.getItem('token') === "null" ||  localStorage.getItem('token') === null ? 
                  <div>
                  <Link to={'/login'}>
                  <button className='login-cta'>Login</button>
                  </Link>
                  <Link to={'/signup'}>
                    <button className='signup-cta'>Sign Up</button>
                  </Link>
                  </div>
                :
               

                <div style={{display:"flex", flexWrap:"space-between"}}>
                <Link to={'/profile'}>
                  <div className='profile-cirlce-wrap'>
                    <div className='.profile-cirlce'>{getInitails()}</div>
                  </div>
                </Link>

                <button onClick = {signOut} className='signup-cta'>Sign Out</button>
                </div>
            }
            </div>
          </div>
        </div> 
    </>
    )
}

export default NavBar