import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { configs } from '../Configs/app-configs';
import CountryDropdown from './CountryDropdown'

function PromoModal({...props}) {
    const [failedValidation, setFailedValidation] = useState(false);
    const [failedValidationMessage, setFailedValidationMessage] = useState("");
    const [operatorId, setOperatorId] = useState(0);


    const navigate = useNavigate();

    useEffect(() => {
     console.log(props.code,localStorage.getItem('countryCallingCode'))
    }, [])
    

    const handleTopUp = async(e) =>{
        e.preventDefault()
        console.log("here", props.accountNumber.length)
        if(props.accountNumber.length < 6){
                setFailedValidation(true)
                setFailedValidationMessage("Number Invalid");
            setTimeout(function(){
                setFailedValidation(false)
            }, 2000)
            
        }else if(props.code === localStorage.getItem('countryCallingCode')){
            setFailedValidation(true)
            setFailedValidationMessage("Can't send to the same country");
        }else{
            //store other details in localstorage
            setLocalStorage()
            //getOperators
            const response = await getOperators();
            //console.log(response, localStorage.getItem('errorMessage'))
        if(response){
            sendSwiffTransaction()
        }else{
            props.setPromoToggle(false)
            props.runErrorMechanism(localStorage.getItem('errorMessage'))
        }

        }
        
    }

    const sendSwiffTransaction = async() => {

        setTimeout(function(){
          props.setSendingToggle(true)
          setTimeout(async function(){
            const response = await axios.post(configs.apiUrl+"/transaction/send/swiff", {
              "recipientNumber": props.accountNumber,
              "code": props.code,
              "operatorId":localStorage.getItem('operatorId'),
              "paymentId":'',
              "email": localStorage.getItem('email'),
              "promo": true,
              "senderCode": localStorage.getItem('countryCallingCode')
            }).then((response) => {
              console.log(response.data)
              props.setSendingToggle(false)
              if(response.status == 200 && response.data.success){
                 localStorage.setItem('hasPromo', "0")
                  props.setSuccessToggle(true)
                  props.setSendingToggle(false);
                  props.setSuccessToggle(true)
                  props.setPromoToggle(false)
                setTimeout(function(){
                    window.location.reload();
                }, 3000)
              }else{
                props.setPromoToggle(false)
                setTimeout(function(){
                    props.runErrorMechanism(response.data.message)
                }, 3000)
                
              }
            })
            
          },6000)
        },1000) 
      

    }
    const getOperators = async() => {
        props.setLoaderToggle(true);
        let response = await axios.get(configs.apiUrl+"/transaction/get/operators/"+props.code+"/"+props.accountNumber+"/"+"airtime")
               .then((response) => {
                 if(response.status == 200 && response.data.data.success){
                   console.log(response, "All Operators: ", response.data.data.operators);
                   setOperator(response.data.data.operators)              
                   props.setLoaderToggle(false);
                   return response.data.data.operators;
                 }else{
                     localStorage.setItem('errorMessage', response.data.data.message ?? 'Error Contact Swiff Support')
                     return false;
                 }
            })
            props.setLoaderToggle(false);
            return response;
        }

    const setOperator = async(op) =>{
        for(var i = 0; i < op.length; i++){
            if(op[i].is_promo === "1"){
                localStorage.setItem('operatorId',op[i].id)
                props.setRecipientGets(op[i].recipient_gets)
                return;
            }
        }
    }

    const setLocalStorage = () =>{
        localStorage.setItem("countryFlag", props.countryFlag)
        localStorage.setItem('callingCodes', JSON.stringify(props.countryCallingCodes))
    }
  return (
      
    <div className="promo-modal">

        <div className="promo-modal-wrapper">
            <form onSubmit={handleTopUp}>
                <div className='promo-modal-nav'>
                    <div className='promo-modal-header'>
                        <img className="giftbox-gif" src="../../../gifs/giftbox.gif" alt="Hamburger-icon"/>
                        <div className='promo-free'>Free</div>
                    </div>
                    <img className="promo-modal-cancel" src="../../favicon/cancel.svg" onClick={() => props.setPromoToggle(!props.promoToggle)} alt="Cancel Icon"/>
                </div>

                <div className='promo-modal-title'>Who are you sending <br/>$1 free top-up to?</div>
                
                {/*Input and Dropdown section*/}
                <div className='promo-recipient-input'>
                    <div className='recipient-input-wrapper' onClick={() => props.setToggle(!props.toggle)}>
                        <img className="country-flag" src={props.countryFlag}  style={{ borderRadius:"50%", overflow:"hidden", objectFit: "cover"}} alt="Ghana flag"/>
                        <div className='country-code'>+{props.code}</div>
                        <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
                    </div>
                    <input 
                    className='recipient-number' 
                    type="text" 
                    name="accountNumber"
                    defaultValue={props.accountNumber}
                    onChange={props.handleChange}
                    placeholder="Enter recipient number"/>
                     
                {props.toggle && (
                    <CountryDropdown
                    name="accountNumber"
                    handleChange={props.handleChange}
                    handleCountrySelect={props.handleCountrySelect}
                    countryCallingCodes={props.countryCallingCodes}
                    />

                )}
                </div>

                {
                      failedValidation && (
                          <span style={{
                              fontSize:"12px", 
                              float:"right",
                              color:"red",
                              paddingTop:"15px",
                              paddingRight:"12px",
                          }}>{failedValidationMessage}</span>
                      )
                  }
               
                {/* <div className="selected-network">
                    <div className="selected-network-detail">
                        <img className="network-logo" src="images/mtn.svg" alt="MTN Logo"/>
                        <div className="network-name">MTN</div>
                    </div>
                    <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="Ghana flag"/>
                </div> */}

                {/*Submit Button*/}
                <input className='promo-modal-cta' type="submit" value="Buy top-up gift"/>
            </form> 
        </div>  
    </div>
    )
}

export default PromoModal