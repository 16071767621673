import React from 'react'

function LoaderModal(){
  return (
    <div className="loader-modal">
        <div className="loader-modal-wrapper">
            <img className="loader-gif" src="../../../gifs/swiff-loader.gif" alt="swiff-icon"/>
        </div>  
    </div>
    )
}

export default LoaderModal