import React, { useState, useEffect } from 'react'
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { configs } from "../../Configs/app-configs";
import axios from "axios";
import PrimaryButton from '../../Components/PrimaryButton';
import { ReactGAImplementation } from 'react-ga4';


export default function Payment({...props}) {

 

  const [isLoading, setIsLoading] = useState(false);


  const [email, setEmail] = useState("");
  
  const [message, setMessage] = useState("");

  const stripe = useStripe();
  const elements = useElements();


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    ReactGAImplementation.send({ hitType: "pageview", page: window.location.pathname, title: "Payment Page" });
    
  }, [stripe]);


  const sendSwiffTransaction = async() => {
    const bundleString = getBundle();
    setTimeout(function(){
      props.setSendingToggle(true)
      setTimeout(async function(){
        const response = await axios.post(configs.apiUrl+"/transaction/send/swiff", {
          "recipientNumber": props.recipientNumber,
          "code": props.code,
          "operatorId":props.operatorId,
          "paymentId":props.paymentId,
          "email": email.value.email,
          "senderCode": localStorage.getItem('countryCallingCode'),
          "bundle": bundleString,
          "promo":false
        }).then((response) => {
          console.log(response.data)
          props.setSendingToggle(false)
          if(response.status == 200){
            props.setSuccessToggle(true)
          }else{
          }
        })
        props.setSendingToggle(false);
        props.setSuccessToggle(true)
  
      },6000)
    },1000) 
  }

  const checkStripePaymentStatus = async(type) => {
    if(type === ''){
      setTimeout(function(){
        props.setSendingToggle(true)
      },1000)
    }
    setTimeout(async function(){
      props.setSendingToggle(false)
      const response = await axios.post(configs.apiUrl+"/transaction/check/payment/status", {
  
        "paymentId":props.paymentId,
       
      }).then((response) => {
        console.log(response.data)
        setTimeout(function(){
          props.setFailureToggle(true);
          props.setFailureMessage(response.data.data.message);
        },3000)
        if(response.status == 200 && response.data.data.status){
            sendSwiffTransaction();
        }else{
            setTimeout(function(){
              props.setFailureToggle(true);
              props.setFailureMessage(response.data.data.message);
            },3000)
         
            props.setFailureToggle(false);
        }
      })

    },2000)
    
    
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    props.setLoaderToggle(true);
 
    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: window.location.href,
      },
      redirect: 'if_required'
    }).then(function(result) {
      console.log(result)
      props.setLoaderToggle(false)
      handleResponse(result)    
    }).catch(function(error){
      props.setLoaderToggle(false)
      if (error.type === "card_error" || error.type === "validation_error") {
        console.log(response.error.message ?? "Something went wrong contact swiff support via our WhatsApp Channel");
      } else {
        console.log("An unexpected error occurred.");
      }
    });
   
  };

  const handleResponse = (response) => {
    switch (response?.paymentIntent?.status) {
      case "succeeded":
        sendSwiffTransaction();
        //checkStripePaymentStatus();      
      break;
      case "processing":
        checkStripePaymentStatus('processing');
      break;
      case "requires_payment_method":
        checkStripePaymentStatus('requires_payment_method');
      break;
      default:
        checkStripePaymentStatus();
      break;
    }
  }

  const getBundle = () => {
    switch(props.purchaseType){
      case 'paytv':
        return localStorage.getItem('customRecipientNumber');
      case 'billpayment':
        return localStorage.getItem('utilityBundle')
      default:
        return ""
    }
  }

  const paymentElementOptions = {
    layout: "tabs"
  }
  
  return (

    <div>
    <form id="payment-form" onSubmit={handleSubmit}>
         
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e)}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
     
      <PrimaryButton disabled={isLoading || !stripe || !elements} id="submit" name='Pay Now'/>

    </form>     
    </div>
  )
}
