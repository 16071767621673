export const configs = {
    apiUrl: 'https://api.trysendswiff.com/api',
    //apiUrl: 'http://34.132.103.222/api',
    imageUrl: 'https://api.trysendswiff.com/storage',

    // apiUrl: 'http://127.0.0.1:8000/api',
    // imageUrl: 'http://localhost:8000/storage',

    returnUrl: 'http://localhost:3000/',
    ipAddressUrl: 'https://api.country.is',

    stripeSecretKey: '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',
    stripePrivateKey: 'pk_live_51N4phmHurFj00W7KfAvIhmg38KBD2FU042ziP1r8yqegRJgVPF44nMTICRAJ6g3QgnPb26yKfp3lIxt1hVfcPraK00d06TGMTz'

    // stripeSecretKey: 'sk_test_V9DtWZSmPaYUhVWCbwfexZb2',
    // stripePrivateKey: 'pk_test_N6y5hXHpI9GDfOa2VgHx53fI'
}