import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { configs } from '../Configs/app-configs';
import CountryDropdown from './CountryDropdown'

function PhoneNumber({...props}) {


    useEffect(() => {
     console.log(props)
     getCountryCallingCodes()
    }, [])
    const getCountryCallingCodes = async() => {
        await axios.get(configs.apiUrl+'/countries').then((res) => {
            if(res.status = 200 && res.data.data.success === true){
            props.setCountryCallingCodes(res.data.data.callingCodesByCountry)
            localStorage.setItem('allCountryCallingCodes',res.data.data.callingCodesByCountry)
            }else{
            props.setCountryCallingCodes(localStorage.getItem('allCountryCallingCodes'))
            }
        })
        .catch((err) => console.log(err));
    }
        

  return (
      
    <div className="promo-modal">

        <div className="promo-modal-wrapper">
            <form onSubmit={props.updateUserData}>
                <div className='promo-modal-nav'>  
                    <img className="promo-modal-cancel" src="../../favicon/cancel.svg" onClick={() => props.setPhoneToggle(!props.phoneToggle)} alt="Cancel Icon"/>
                </div>

                <div className='promo-modal-title'>Add phone number</div>
                
                {/*Input and Dropdown section*/}
                <div className='promo-recipient-input'>
                    <div className='recipient-input-wrapper' onClick={() => props.setToggle(!props.toggle)}>
                        <img className="country-flag" src={props.countryFlag}  style={{ borderRadius:"50%", overflow:"hidden", objectFit: "cover"}} alt="Ghana flag"/>
                        <div className='country-code'>+{props.code}</div>
                        <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
                    </div>
                    <input 
                    className='recipient-number' 
                    type="text" 
                    name="phoneNumber"
                    defaultValue={props.phoneNumber}
                    onChange={props.handleChange}
                    placeholder="Enter recipient number"/>
                     
                {props.toggle && (
                    <CountryDropdown
                    name="accountNumber"
                    handleChange={props.handleChange}
                    handleCountrySelect={props.handleCountrySelect}
                    countryCallingCodes={props.countryCallingCodes}
                    />

                )}
                </div>

                {
                      props.failedValidation && (
                          <span style={{
                              fontSize:"12px", 
                              float:"right",
                              color:"red",
                              paddingTop:"15px",
                              paddingRight:"12px",
                          }}>{props.failedValidationMessage}</span>
                      )
                  }
               
              
                {/*Submit Button*/}
                <input className='promo-modal-cta' type="submit" value="Add"/>
            </form> 
        </div>  
    </div>
    )
}

export default PhoneNumber;