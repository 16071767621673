import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CountryDropdown from './CountryDropdown'

function EditModal({...props}) {
    const [failedValidation, setFailedValidation] = useState(false);
    const [failedValidationMessage, setFailedValidationMessage] = useState("");
    const navigate = useNavigate();

    const handleTopUp = (e) =>{
        e.preventDefault()
        console.log("here", props.accountNumber.length)
        if(props.accountNumber.length < 6){
                setFailedValidation(true)
                setFailedValidationMessage("Number Invalid");
            setTimeout(function(){
                setFailedValidation(false)
            }, 2000)
            
        }else{
        //store other details in localstorage
        setLocalStorage()
        
        //set country phone code and redirect to top up panel page
        navigate("/topup/"+props.code+"/"+props.accountNumber);
        //remove edit modal
        props.setEditToggle(false);
        }
        
    }

    const setLocalStorage = () =>{
        localStorage.setItem("countryFlag", props.countryFlag)
        localStorage.setItem('callingCodes', JSON.stringify(props.countryCallingCodes))
    }
  return (
    <div className="edit-modal">
        <div className="edit-modal-wrapper">
            <form onSubmit={handleTopUp}>
                <div className='edit-modal-nav'>
                    <div className='edit-modal-header'>Edit Receiver Details</div>
                    <img className="edit-modal-cancel" src="../../favicon/cancel.svg" onClick={() => props.setEditToggle(false)} alt="Cancel Icon"/>
                </div>
                {/*Input and Dropdown section*/}
                <div className='edit-recipient-input'>
                    <div className='recipient-input-wrapper' onClick={() => props.setToggle(!props.toggle)}>
                        <img className="country-flag" src={props.countryFlag}  style={{ borderRadius:"50%", overflow:"hidden", objectFit: "cover"}} alt="Ghana flag"/>
                        <div className='country-code'>+{props.code}</div>
                        <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="arrow-down-icon"/>
                    </div>
                    <input 
                    className='recipient-number' 
                    type="text" 
                    name="accountNumber"
                    defaultValue={props.accountNumber}
                    onChange={props.handleChange}
                    placeholder="Enter recipient number"/>
                     
                {props.toggle && (
                    <CountryDropdown
                    name="accountNumber"
                    handleChange={props.handleChange}
                    handleCountrySelect={props.handleCountrySelect}
                    countryCallingCodes={props.countryCallingCodes}
                    />

                )}
                </div>

                {
                      failedValidation && (
                          <span style={{
                              fontSize:"12px", 
                              float:"right",
                              color:"red",
                              paddingTop:"15px",
                              paddingRight:"12px",
                          }}>{failedValidationMessage}</span>
                      )
                  }
               
                {/* <div className="selected-network">
                    <div className="selected-network-detail">
                        <img className="network-logo" src="images/mtn.svg" alt="MTN Logo"/>
                        <div className="network-name">MTN</div>
                    </div>
                    <img className="arrow-down" src="../../favicon/arrow-down.svg" alt="Ghana flag"/>
                </div> */}

                {/*Submit Button*/}
                <input className='edit-modal-cta' type="submit" value="Update"/>
            </form> 
        </div>  
    </div>
    )
}

export default EditModal