import React from 'react'
import PrimaryButton from './PrimaryButton'


function SuccessModal({...props}){
    
  return (
    <div className="success-modal">
        <div className="success-modal-wrapper">
            <div className="success-gif-container">
                <img className="success-gif" src="../../../gifs/success.gif" alt="Success-icon"/>
            </div>
            <div className="success-modal-title">Payment Successful</div>
            <div className="success-modal-dynamic-text">We’ll send your receipt to your email.</div>
            <div> 
            <button onClick={() => props.closeAndOpenModals(true)} className='main-primary-button'>
                {(localStorage.getItem('token') === '') ? 'Sign Up' : 'Ok'}
            </button>   
        </div>
        </div>  
    </div>
    )
}

export default SuccessModal