import React, { useEffect, useState } from 'react'
import './Signup.css';
import NavBar from '../../Components/NavBar';
import { Link, useNavigate } from 'react-router-dom';
import LoaderModal from '../../Components/LoaderModal';
import axios from 'axios';
import { configs } from '../../Configs/app-configs';
import FailedModal from '../../Components/FailedModal';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import ReactGA from  'react-ga4';
import DynamicSuccessModal from '../../Components/DynamicSuccessModal';
import CountryDropdown from '../../Components/CountryDropdown';




export default function Signup() {
    const [loaderToggle, setLoaderToggle] = useState(false);
    const [failureToggle, setFailureToggle] = useState(false);
    const[message, setMessage] = useState("");
    const[fullName, setFullName] = useState("");
    const[email, setEmail] = useState("");
    const[phoneNumber, setPhoneNumber] = useState("");
    const[password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [ user, setUser ] = useState([]);
    const [countryCallingCodes, setCountryCallingCodes] = useState([]);
    const [callingCode, setCallingCode] = useState("");
    const [countryFlag, setCountryFlag] = useState("");
    const [toggle, setToggle] = useState(false);





    //dynamic success dependencies
    const [dynamicSuccessModalToggle, setDynamicSuccessModalToggle] = useState(false);
    const [dynamicSuccessTitle, setDynamicSuccessTitle] = useState("");
    const [dynamicSuccessMessage, setDynamicSuccessMesssage] = useState("");


    const navigate = useNavigate()


    useEffect(() => {
        setCallingCode("233")
       
        setCountryFlag("https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Signup Page" });
    }, [])


    const signup = (e) =>{
        e.preventDefault();
        setLoaderToggle(true)
        setTimeout(function(){
            setLoaderToggle(true)
            setTimeout(function(){
                axios.post(configs.apiUrl+'/auth/create',{ 
                    'full_name':fullName,
                    'email':email,
                    'phone_number':phoneNumber,
                    'password':password,
                    'ip': localStorage.getItem('ip'),
                    'country_calling_code': localStorage.getItem('countryCallingCode'),//actually the cached country code
                    'calling_code': callingCode,//this is the chosen country code
                    'device_details': {
                        'browser_code_name': navigator.appCodeName,
                        'browser_name': navigator.appName,
                        'browser_version': navigator.appVersion,
                        'browser_language': navigator.language,
                        'browser_online': navigator.onLine,
                        'browser_platform': navigator.platform,
                        'browser_agent': navigator.userAgent
                    }
                  },{headers:{
                      'ContentType':'application/json'
                  }}).then(response => {
               console.log(response.data);
               if(response.status === 200){
                    if(response.status === 200 && response.data.success){ 
                    //setAuthDetails(response)
                    if(response.data.data.email_confirmed === '0'){
                        localStorage.setItem('email', response.data.data.email);
                        setTimeout(function(){
                            setTimeout(function(){
                                navigate("/login");
                            }, 7000)
                            showSuccess('Redirecting.....', 'Thanks for Signing Up')
                            //showSuccess('Please Check Your Spam As Well', 'An email has been sent to you for confirmation')
                        }, 1000)
                    }else{
                        navigate("/login");
                    }
                }else{
                    runErrorMechanism(response)
                }
               }else{
                   runErrorMechanism(response)
               }
           }); 
           setLoaderToggle(true)

            }, 1000)
            setLoaderToggle(true)
        }, 3000)
        setLoaderToggle(false)    

    }

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            //console.log(codeResponse)
            setUser(codeResponse)
        
        },
        onError: (error) => console.log('Login Failed:', error)
    });
    
    useEffect(
        () => {
            if (user) {
                axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        console.log(res.data)
                        loginWithoutPassword(res);
                        //setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }

            getCountryCallingCodes()
        },
        [ user ]
    );

    const getCountryCallingCodes = async() => {
        await axios.get(configs.apiUrl+'/countries').then((res) => {
            if(res.status = 200 && res.data.data.success === true){
            setCountryCallingCodes(res.data.data.callingCodesByCountry)
            localStorage.setItem('allCountryCallingCodes',res.data.data.callingCodesByCountry)
            }else{
            setCountryCallingCodes(localStorage.getItem('allCountryCallingCodes'))
            }
        })
        .catch((err) => console.log(err));
            
        
    }
    
    const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "full_name") {
        setFullName(value);
    } 
    if (name === "password") {
        setPassword(value);
    } 
    if (name === "email") {
        setEmail(value);
    } 

    if (name === "phoneNumber") {
        setPhoneNumber(value);
    } 
    }
    const loginWithoutPassword = (res) =>{
        setLoaderToggle(true)
        setTimeout(function(){
            setLoaderToggle(true)
            setTimeout(function(){
                axios.post(configs.apiUrl+'/loginWithoutPassword',{ 
                    'full_name':res.data.name,
                    'username':res.data.given_name,
                    'email':res.data.email,
                    'profile_pic':res.data.picture,
                    'sso_objects': res.data,
                    'sso_id':user.access_token,
                    'ip': localStorage.getItem('ip'),
                    'country_calling_code': localStorage.getItem('countryCallingCode'),//actually the cached country code
                    'calling_code': callingCode,//this is the chosen country code
                    'device_details': {
                        'browser_code_name': navigator.appCodeName,
                        'browser_name': navigator.appName,
                        'browser_version': navigator.appVersion,
                        'browser_language': navigator.language,
                        'browser_online': navigator.onLine,
                        'browser_platform': navigator.platform,
                        'browser_agent': navigator.userAgent
                    }                  
                },{headers:{
                      'ContentType':'application/json'
                  }}).then(response => {
               console.log(response.data);
               if(response.status === 200){
                setAuthDetails(response)
                if(response.data.user.email_confirmed === '0'){
                    //showSuccess('', 'An email has been sent to you for confirmation')
                    navigate("/profile");
                }else{
                    navigate("/profile");       
                }
               }else{
                   runErrorMechanism(response)
               }
               setLoaderToggle(false)
           }); 
           setLoaderToggle(true)

            }, 1000)
            setLoaderToggle(true)
        }, 3000)
        setLoaderToggle(false)   
        
    }
    const setFailureMessage = (message) =>{
    setMessage(message)
    }

    const runErrorMechanism = (response) =>{
        setFailureMessage(response.data.message ?? "Something went wrong, contact Swiff Support")
        setFailureToggle(true);
        setLoaderToggle(false);

    }


    const setAuthDetails = (response) =>{
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('phoneNumber', response.data.user.phone_number)
        localStorage.setItem('fullname', response.data.user.full_name)
        localStorage.setItem('email', response.data.user.email)
        localStorage.setItem('ssoId', response.data.user.sso_id)
        localStorage.setItem('profilePic', response.data.user.profile_pic)
        localStorage.setItem('hasPromo', response.data.user.has_promo)
        localStorage.setItem('emailConfirmed', response.data.user.email_confirmed)

    }


    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }

      const showSuccess = (title, messageDetails) =>{
        setDynamicSuccessMesssage(title);
        setDynamicSuccessTitle(messageDetails);
        setTimeout(function(){
          setDynamicSuccessModalToggle(true)
        }, 1000);
        setDynamicSuccessModalToggle(false)
        setLoaderToggle(false)    
    
      }
      const handleCountrySelect = (code,flag) =>{
        setCallingCode(code);
        setCountryFlag(flag)
        setTimeout(function(){
            setToggle(!toggle);
        }, 500)
      }
  return (
    <>
    <NavBar/>
        {
          loaderToggle && (
            <LoaderModal/>
            )
          }

        {
          failureToggle && (
            <FailedModal message={message} failureToggle={failureToggle} setFailureToggle={setFailureToggle}/>
            )
          }

        {
            dynamicSuccessModalToggle && (
              <DynamicSuccessModal message={dynamicSuccessMessage}  
              title={dynamicSuccessTitle}  
              dynamicSuccessModalToggle={dynamicSuccessModalToggle}
              setDynamicSuccessModalToggle={setDynamicSuccessModalToggle}
              />
            )
          }
    <div className="signup-container">
        <div className="signup-wrapper">
            <form onSubmit={signup}>
                <div className='signup-modal-nav'>
                    <div className='signup-modal-header'>Sign up</div>
                </div>
                <div className='signup-modal-subheader'>Stay connected across borders! Sign up now to effortlessly send airtime and data to loved ones abroad.</div>
                {/*Input and Dropdown section*/}
                <ul className='signup-input-fields'>
                    <li>
                        <input 
                        className="signup-input" 
                        type='text' 
                        placeholder='Full Name'
                        name="full_name"
                        required
                        defaultValue={fullName}
                        onChange={handleChange}
                        />
                    </li>
                    <li>
                        <input 
                        className="signup-input" 
                        type='email' 
                        placeholder='Email'
                        name="email"
                        required
                        defaultValue={email}
                        onChange={handleChange}
                        />
                    </li>
                    <li>
                    <div className='recipient-input' >
                    <div className='recipient-input-wrapper' onClick={() => setToggle(!toggle)}>
                      <img className="country-flag" style={{ borderRadius:"60%", overflow:"hidden", objectFit: "cover"}}  src={countryFlag} alt="Ghana flag"/>
                      <div className='country-code'>+{callingCode}</div>
                      <img className="arrow-down" src="favicon/arrow-down.svg" alt="arrow-down-icon"/>
                    </div>
                    <input 
                    className='recipient-number' 
                    name='phoneNumber'
                    onChange={handleChange}
                    type="text" 
                    placeholder="Phone number"
                    required
                    defaultValue={phoneNumber}                    
                    />
                              
                    {toggle && (
                      <CountryDropdown
                        handleCountrySelect={handleCountrySelect}
                        countryCallingCodes={countryCallingCodes}
                      />
                    )}
                  </div>
                    </li>
                    <li className="signup-password">
                        <input 
                        className="signup-password-input" 
                        type={passwordType} 
                        placeholder='Password'
                        name="password"
                        required
                        defaultValue={password}
                        onChange={handleChange}
                        />
                        { passwordType === "password" ? 
                        <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/hide.svg"   alt="hide Icon"/> :
                        <img onClick={togglePassword} className="signup-password-icon" src="../../../favicon/show.svg"   alt="hide Icon"/> }
                    </li>
                </ul>

                {/*Submit Button*/}
                <div className='signup-modal-ctas'>
                        <input className='signup-primary-cta' type="submit" value="Signup"/>
                    <div className="signup-secondary-cta">
                        <img className="signup-google-icon" src="../../../images/google.png" alt="hide Icon"/>
                        <input className="signup-google-signup" onClick={() => loginGoogle()} type='submit' value="Sign up with Google"/>
                    </div>
                </div>
            </form> 
            {/* Redirect to Login up */}
            <div className='signup-redirect-cta'>
                Already have an account? 
                <Link to={'/Login'} >
                    <span className='signup-redirect-link'>Login</span>
                </Link>
            </div>
        </div>  
    </div>
    </>
  )
}
