import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { configs } from '../Configs/app-configs';




function ReceiptModal({...props}){

    const[username, setUsername] = useState("");
    const[email, setEmail] = useState("");
    const[phoneNumber, setPhoneNumber] = useState("");
    const[password, setPassword] = useState("");
    const navigate = useNavigate()

    const signupAndGetReceipt = (e) =>{
        e.preventDefault();
        axios.post(configs.apiUrl+'/register',{ 
                 'username':username,
                 'email':email,
                 'phoneNumber':phoneNumber,
                 'password':password
               },{headers:{
                   'ContentType':'application/json'
               }}).then(response => {
            console.log(response.data);
            if(response.status === 200){
                navigate("/");
            }
        });
        
   }

   const closeAndRedirect = () =>{
    props.setReceiptToggle(!props.receiptToggle)
    setTimeout(function(){
        navigate("/")
    }, 1000)
    
   }

   const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
        setUsername(value);
    } 
    if (name === "password") {
        setPassword(value);
    } 
    if (name === "email") {
        setEmail(value);
    } 

    if (name === "phoneNumber") {
        setPhoneNumber(value);
    } 
}
  return (
    <div className="receipient-modal">
        <div className="receipient-modal-wrapper">
            <form onSubmit={signupAndGetReceipt}>
                <div className='receipient-modal-nav'>
                    <div className='receipient-modal-header'>Get Payment Receipt</div>
                    <img className="receipient-modal-cancel" onClick={() => closeAndRedirect()}src="../../../favicon/cancel.svg" alt="Cancel Icon"/>
                </div>
                <div className='receipient-modal-subheader'>You’ll have to complete sign up so we can send you the receipt for your gift payment.</div>
                {/*Input and Dropdown section*/}
                <ul className='receipient-input-fields'>
                    <li>
                        <input 
                        className="receipient-input" 
                        type='text' 
                        placeholder='Username'
                        name="username"
                        required
                        defaultValue={username}
                        onChange={handleChange}
                        />
                    </li>
                    <li>
                        <input 
                        className="receipient-input" 
                        type='email' 
                        placeholder='Email'
                        name="email"
                        required
                        defaultValue={email}
                        onChange={handleChange}
                        />
                    </li>
                    <li>
                        <input 
                        className="receipient-input" 
                        placeholder='Phone Number'
                        name="phoneNumber"
                        required
                        type="number" pattern="[789][0-9]{9}"
                        defaultValue={phoneNumber}
                        onChange={handleChange}
                        />
                    </li>
                    <li className="receipient-password">
                        <input 
                        className="receipient-password-input" 
                        type='password' 
                        placeholder='Password'
                        name="password"
                        required
                        defaultValue={password}
                        onChange={handleChange}
                        />
                        <img className="receipient-password-icon" src="../../../favicon/hide.svg" alt="hide Icon"/>
                    </li>
                </ul>

                {/*Submit Button*/}
                <div className='receipient-modal-ctas'>
                    <input className='receipient-primary-cta' type="submit" value="Sign up to complete"/>
                    <div className="receipient-secondary-cta">
                        <img className="receipient-google-icon" src="../../../images/google.png" alt="hide Icon"/>
                        <input className="receipient-google-signup" type='submit' value="Sign up with Google"/>
                    </div>
                </div>
            </form> 
            
            {/*Redirect to Login up
            <div>Already have an account? 
                <span>Login</span>
            </div>
            */}
        </div>  
    </div>
    )
}

export default ReceiptModal